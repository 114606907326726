$bordure-panel : 2px;

.panel{

	.panel-header{
		padding: 20px 20px 0px 20px;
		.panel-title{
			font-size: 20px;
			font-weight: 400;
		}
		.panel-subtitle{
			font-size: 14px;
			color: $sombre;
			display: block;
			margin-top: 5px;
		}
	}
	.panel-body{
		padding: 20px 10px;
	}
	.panel-footer{
		padding: 10px;
		background: $clair;
	}
}

@media only screen and (max-width: $s-width) {
	.panel-body{
		padding: 10px 0!important;
	}
	.panel-header{
		padding: 10px 10px 0px 10px!important;
	}
}