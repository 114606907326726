.card{
	width: $largeurCard;
	min-width: $largeurCard;
	border-right: 1px solid $gris;
	overflow: auto;

	.card-header{
		padding: 10px;
		text-align: center;
		background: $clair;
		border-bottom: 1px solid $gris;

		@each $label, $couleur in $couleurs {
			&.#{$label}{
				background: $couleur;
				@if $label == 'gris' {
					color: $dark;
				} @else {
					color: #FFF;
				}
			}
		}

		.card-icon{ font-size: 30px; }
		.card-title{
			font-weight: 300;
			font-size: 16px;
			margin-top: 10px;
			text-transform: uppercase;
		}
		.card-update{
			font-size: 12px;
			font-weight: 300;
			margin-top: 5px;
		}
	}
	.card-bloc{
		padding: 10px 5px;
		.card-bloc-title{
			font-size: 17px;
			margin-left: 10px;
		}
	}

	.card-list{
		width: 100%;
		tr{
			border-bottom: 1px solid $gris;
			td:first-child{ color: $sombre; }
			td{
				padding: 15px 10px;

				input, select{
					height: 30px;
				}
			}
		}

		input{
			background: $gris;
		}
	}

	.card-actions{
		margin-top: 10px;
		text-align: center;
		a{ color: $sombre; }
	}
}

@media only screen and (max-width: $m-width) {
	.card{
		display: block;
		border: none;
		width: 100%;
		min-width: 100%;
	}
}