/***********************************
---- Palette de couleurs ----
***********************************/
// Couleurs principales
$bleu       : #0588d0;
$cyan       : #1eb4c3;
$vert       : #3FC380;
$violet     : #7e4ee7;
$rose       : #e76b8a;
$rouge      : #e74c3c;
$orange     : #F29B34;
$jaune      : #F7CA18;

// Dégradés gris
$noir       : #000;
$fonce      : #323232;
$dark       : #4A535A;
$sombre     : #868686;
$gris       : #E4E8EB;
$gris-clair : #DEE3E9;
$clair      : #F8F9FB;

// Couleur principale de l'application
$main       : $bleu;

$couleurs : ('bleu', $bleu),
            ('vert', $vert),
            ('rouge', $rouge),
            ('orange', $orange),
            ('jaune', $jaune),
            ('fonce', $fonce),
            ('sombre', $sombre),
            ('noir', $noir),
            ('rose', $rose),
            ('violet', $violet),
            ('cyan', $cyan),
            ('gris', $gris);

/***********************************
---- Autres variables ----
***********************************/
$m-width    : 1040px;
$s-width    : 640px;
$default-transition-property : all;
$default-transition-duration : .2s;