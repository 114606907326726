.tabs{
	background: $clair;
	border-bottom: 1px solid $gris;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;

	& > a{
		color: $sombre;
		font-weight: 500;
		display: inline-block;
		margin: 0 5px;
		padding: 20px 10px;

		&.active{
			border-bottom: 3px solid $main;
			color: $main;
		}

		&:hover{ border-bottom: 3px solid $main; }
	}

	.actions{
		margin-top: 10px;
		margin-left: auto;
	}
}

.tabs-menu{
	display: block;
	background: $dark;
	color: lighten($dark, 30);
	text-transform: uppercase;
	padding: 15px 10px;
}

@media only screen and (max-width: $s-width) {
	.tabs{
		flex-direction: column;
	}
}

.tab-content{
	display: none;
	&.active{
		display: block;
	}
}