@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/***********************************
---- Palette de couleurs ----
***********************************/
/***********************************
---- Autres variables ----
***********************************/
/***********************************
---- Variables ----
***********************************/
/***********************************
---- Règles générales ----
***********************************/
.col-s-1, .col-m-1, col-l-1, .col-s-2, .col-m-2, col-l-2, .col-s-3, .col-m-3, col-l-3, .col-s-4, .col-m-4, col-l-4, .col-s-5, .col-m-5, col-l-5, .col-s-6, .col-m-6, col-l-6, .col-s-7, .col-m-7, col-l-7, .col-s-8, .col-m-8, col-l-8, .col-s-9, .col-m-9, col-l-9, .col-s-10, .col-m-10, col-l-10, .col-s-11, .col-m-11, col-l-11, .col-s-12, .col-m-12, col-l-12 {
  float: left;
  position: relative;
  min-height: 1px;
  padding: 0 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.row {
  margin-bottom: 5px;
}

.row:before, .row:after {
  content: " ";
  display: table;
}

.row:after {
  clear: both;
}

/***********************************
---- Responsives ----
***********************************/
.col-s-push-0 {
  left: 0%;
}

.col-s-offset-0 {
  margin-left: 0%;
}

.col-s-1 {
  width: 8.3333333333%;
}

.col-s-push-1 {
  left: 8.3333333333%;
}

.col-s-offset-1 {
  margin-left: 8.3333333333%;
}

.col-s-2 {
  width: 16.6666666667%;
}

.col-s-push-2 {
  left: 16.6666666667%;
}

.col-s-offset-2 {
  margin-left: 16.6666666667%;
}

.col-s-3 {
  width: 25%;
}

.col-s-push-3 {
  left: 25%;
}

.col-s-offset-3 {
  margin-left: 25%;
}

.col-s-4 {
  width: 33.3333333333%;
}

.col-s-push-4 {
  left: 33.3333333333%;
}

.col-s-offset-4 {
  margin-left: 33.3333333333%;
}

.col-s-5 {
  width: 41.6666666667%;
}

.col-s-push-5 {
  left: 41.6666666667%;
}

.col-s-offset-5 {
  margin-left: 41.6666666667%;
}

.col-s-6 {
  width: 50%;
}

.col-s-push-6 {
  left: 50%;
}

.col-s-offset-6 {
  margin-left: 50%;
}

.col-s-7 {
  width: 58.3333333333%;
}

.col-s-push-7 {
  left: 58.3333333333%;
}

.col-s-offset-7 {
  margin-left: 58.3333333333%;
}

.col-s-8 {
  width: 66.6666666667%;
}

.col-s-push-8 {
  left: 66.6666666667%;
}

.col-s-offset-8 {
  margin-left: 66.6666666667%;
}

.col-s-9 {
  width: 75%;
}

.col-s-push-9 {
  left: 75%;
}

.col-s-offset-9 {
  margin-left: 75%;
}

.col-s-10 {
  width: 83.3333333333%;
}

.col-s-push-10 {
  left: 83.3333333333%;
}

.col-s-offset-10 {
  margin-left: 83.3333333333%;
}

.col-s-11 {
  width: 91.6666666667%;
}

.col-s-push-11 {
  left: 91.6666666667%;
}

.col-s-offset-11 {
  margin-left: 91.6666666667%;
}

.col-s-12 {
  width: 100%;
}

.col-s-push-12 {
  left: 100%;
}

.col-s-offset-12 {
  margin-left: 100%;
}

.col-s-center {
  margin: 0 auto;
  float: none;
}

@media only screen and (min-width: 640px) {
  .col-m-push-0 {
    left: 0%;
  }
  .col-m-offset-0 {
    margin-left: 0%;
  }
  .col-m-1 {
    width: 8.3333333333%;
  }
  .col-m-push-1 {
    left: 8.3333333333%;
  }
  .col-m-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-m-2 {
    width: 16.6666666667%;
  }
  .col-m-push-2 {
    left: 16.6666666667%;
  }
  .col-m-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-m-3 {
    width: 25%;
  }
  .col-m-push-3 {
    left: 25%;
  }
  .col-m-offset-3 {
    margin-left: 25%;
  }
  .col-m-4 {
    width: 33.3333333333%;
  }
  .col-m-push-4 {
    left: 33.3333333333%;
  }
  .col-m-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-m-5 {
    width: 41.6666666667%;
  }
  .col-m-push-5 {
    left: 41.6666666667%;
  }
  .col-m-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-m-6 {
    width: 50%;
  }
  .col-m-push-6 {
    left: 50%;
  }
  .col-m-offset-6 {
    margin-left: 50%;
  }
  .col-m-7 {
    width: 58.3333333333%;
  }
  .col-m-push-7 {
    left: 58.3333333333%;
  }
  .col-m-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-m-8 {
    width: 66.6666666667%;
  }
  .col-m-push-8 {
    left: 66.6666666667%;
  }
  .col-m-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-m-9 {
    width: 75%;
  }
  .col-m-push-9 {
    left: 75%;
  }
  .col-m-offset-9 {
    margin-left: 75%;
  }
  .col-m-10 {
    width: 83.3333333333%;
  }
  .col-m-push-10 {
    left: 83.3333333333%;
  }
  .col-m-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-m-11 {
    width: 91.6666666667%;
  }
  .col-m-push-11 {
    left: 91.6666666667%;
  }
  .col-m-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-m-12 {
    width: 100%;
  }
  .col-m-push-12 {
    left: 100%;
  }
  .col-m-offset-12 {
    margin-left: 100%;
  }
  .col-m-center {
    margin: 0 auto;
    float: none;
  }
}

@media only screen and (min-width: 1040px) {
  .col-l-push-0 {
    left: 0%;
  }
  .col-l-offset-0 {
    margin-left: 0%;
  }
  .col-l-1 {
    width: 8.3333333333%;
  }
  .col-l-push-1 {
    left: 8.3333333333%;
  }
  .col-l-offset-1 {
    margin-left: 8.3333333333%;
  }
  .col-l-2 {
    width: 16.6666666667%;
  }
  .col-l-push-2 {
    left: 16.6666666667%;
  }
  .col-l-offset-2 {
    margin-left: 16.6666666667%;
  }
  .col-l-3 {
    width: 25%;
  }
  .col-l-push-3 {
    left: 25%;
  }
  .col-l-offset-3 {
    margin-left: 25%;
  }
  .col-l-4 {
    width: 33.3333333333%;
  }
  .col-l-push-4 {
    left: 33.3333333333%;
  }
  .col-l-offset-4 {
    margin-left: 33.3333333333%;
  }
  .col-l-5 {
    width: 41.6666666667%;
  }
  .col-l-push-5 {
    left: 41.6666666667%;
  }
  .col-l-offset-5 {
    margin-left: 41.6666666667%;
  }
  .col-l-6 {
    width: 50%;
  }
  .col-l-push-6 {
    left: 50%;
  }
  .col-l-offset-6 {
    margin-left: 50%;
  }
  .col-l-7 {
    width: 58.3333333333%;
  }
  .col-l-push-7 {
    left: 58.3333333333%;
  }
  .col-l-offset-7 {
    margin-left: 58.3333333333%;
  }
  .col-l-8 {
    width: 66.6666666667%;
  }
  .col-l-push-8 {
    left: 66.6666666667%;
  }
  .col-l-offset-8 {
    margin-left: 66.6666666667%;
  }
  .col-l-9 {
    width: 75%;
  }
  .col-l-push-9 {
    left: 75%;
  }
  .col-l-offset-9 {
    margin-left: 75%;
  }
  .col-l-10 {
    width: 83.3333333333%;
  }
  .col-l-push-10 {
    left: 83.3333333333%;
  }
  .col-l-offset-10 {
    margin-left: 83.3333333333%;
  }
  .col-l-11 {
    width: 91.6666666667%;
  }
  .col-l-push-11 {
    left: 91.6666666667%;
  }
  .col-l-offset-11 {
    margin-left: 91.6666666667%;
  }
  .col-l-12 {
    width: 100%;
  }
  .col-l-push-12 {
    left: 100%;
  }
  .col-l-offset-12 {
    margin-left: 100%;
  }
  .col-l-center {
    margin: 0 auto;
    float: none;
  }
}

.col-l-hide {
  display: none;
}

@media only screen and (max-width: 640px) {
  .col-s-hide {
    display: none !important;
  }
}

@media only screen and (max-width: 1040px) {
  .col-m-hide {
    display: none;
  }
}

.col-s-show {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .col-s-show {
    display: block !important;
  }
}

/***********************************
---- Fonts ----
***********************************/
/***********************************
---- Règles générales ----
***********************************/
* {
  outline: none;
}

*:focus {
  outline: none;
}

*:active {
  outline: none;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #323232;
  background: #FFF;
  width: 100%;
  height: 100%;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
}

@media only screen and (max-width: 640px) {
  body.menu-open {
    overflow: hidden;
    position: fixed;
  }
}

a {
  color: #0588d0;
  text-decoration: none;
}

a.lien-icon {
  color: #868686;
  padding: 5px 10px;
  border: 1px solid #868686;
  border-radius: 30px;
}

a.lien-icon:hover {
  border-color: #0588d0;
  color: #0588d0;
}

i {
  color: inherit;
}

strong {
  font-weight: 600;
}

.brand {
  text-align: center;
  margin: 0 auto 40px auto;
  display: block;
  text-transform: uppercase;
  color: #323232;
  font-size: 16px;
  font-weight: 400;
}

.brand img {
  width: 130px;
}

.brand .name {
  color: #0588d0;
  font-weight: 600;
}

.page-header {
  background: #F8F9FB;
  border-bottom: 1px solid #E4E8EB;
  padding: 15px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-header .page-title {
  color: #0588d0;
  font-size: 20px;
  font-weight: 300;
}

.page-header .page-actions {
  margin-left: auto;
}

/***********************************
---- Helper Classes ----
***********************************/
.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.help-text {
  color: #868686;
  font-size: 13px;
  margin: 10px 0;
}

.no-border {
  border: none !important;
}

.show {
  display: block;
}

.no-data {
  text-align: center;
  color: #868686;
  margin: 20px 0;
  font-size: 15px;
  text-transform: uppercase;
}

.no-data-more {
  display: block;
  font-size: 11px;
  margin-top: 10px;
}

.date-format {
  text-transform: capitalize;
}

/***********************************
---- PAGE D'ERREUR ----
***********************************/
.page-error {
  text-align: center;
}

.page-error .page-error-code {
  font-size: 40px;
  color: #868686;
  margin-bottom: 30px;
  font-weight: 300;
}

.page-error .page-error-message {
  font-size: 30px;
  margin-bottom: 10px;
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

.icon-link {
  font-size: 18px;
  color: #868686;
  margin-right: 5px;
  position: relative;
}

.icon-link:hover {
  color: #0588d0;
}

.tranfer-icon {
  color: #868686;
  font-size: 30px;
  margin: 0 20px;
  vertical-align: middle;
}

.hide {
  display: none;
}

.border-bottom {
  border-bottom: 1px solid #E4E8EB;
}

.no-border-bottom {
  border-bottom: none !important;
}

/***********************************
---- Variables ----
***********************************/
/***********************************
---- Topbar ----
***********************************/
.topbar {
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #FFF;
  border-bottom: 1px solid #E4E8EB;
  height: 45px;
  line-height: 45px;
  text-align: center;
  color: #323232;
}

.topbar .brand {
  display: inline-block;
}

.topbar .brand img {
  width: 100px;
  vertical-align: middle;
}

.topbar .topbar-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 45px;
  border-radius: 50%;
}

.topbar .topbar-menu span, .topbar .topbar-menu span::before, .topbar .topbar-menu span::after {
  position: absolute;
  display: block;
  background: #0588d0;
  width: 22px;
  height: 2px;
  transition: all 0.2s;
}

.topbar .topbar-menu p {
  margin-left: 45px;
}

.topbar .topbar-menu span {
  top: 50%;
  left: 35%;
  transform: translateX(-50%) translateY(-50%);
}

.topbar .topbar-menu span::before, .topbar .topbar-menu span::after {
  content: '';
  top: 0;
  left: 0;
  transform: translateY(-6px);
  transform-origin: 50% 50%;
}

.topbar .topbar-menu span::after {
  transform: translateY(6px);
}

.topbar .topbar-menu:hover {
  cursor: pointer;
}

.topbar .topbar-menu:hover span::before {
  transform: translateY(-8px);
}

.topbar .topbar-menu:hover span::after {
  transform: translateY(8px);
}

.topbar .topbar-menu.open span {
  background: transparent;
}

.topbar .topbar-menu.open span::before {
  transform: translateY(0) rotate(45deg);
}

.topbar .topbar-menu.open span::after {
  transform: translateY(0) rotate(-45deg);
}

.topbar .topbar-menu.open span::before, .topbar .topbar-menu.open span::after {
  transform-origin: 50% 50%;
}

.topbar .topbar-actions {
  position: absolute;
  top: 0;
  right: 20px;
}

.topbar .topbar-actions > ul > li {
  position: relative;
  display: inline-block;
}

.topbar .topbar-actions > ul > li > a {
  padding: 0 15px;
  position: relative;
}

.topbar .topbar-actions > ul > li > a > i {
  vertical-align: middle;
  font-size: 22px;
  color: #868686;
}

.topbar .topbar-actions > ul > li > a > i.drop-down {
  color: #0588d0;
  font-size: inherit;
}

.topbar .profil-avatar {
  vertical-align: middle;
  margin-right: 5px;
}

/***********************************
---- Menu ----
***********************************/
.menu {
  background: #FFF;
  width: 110px;
  min-width: 110px;
  height: 100%;
  color: #868686;
  border-right: 1px solid #E4E8EB;
  text-align: center;
  box-sizing: border-box;
  transition-property: margin-left;
  transition-duration: 0.2s;
  overflow: auto;
}

.menu.close {
  display: none;
}

.menu nav {
  display: flex;
  flex-direction: column;
}

.menu nav a {
  position: relative;
  display: block;
  padding: 10px 0;
  margin: 5px 0;
  width: 100%;
  line-height: 22px;
  font-weight: 400;
  font-size: 14px;
  color: #868686;
}

.menu nav a.active {
  color: #4A535A;
}

.menu nav a.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #0588d0;
}

.menu nav a.active i {
  color: #0588d0;
}

.menu nav a:hover {
  color: #0588d0;
}

.menu nav a:hover i {
  color: #0588d0;
}

.menu nav a i {
  display: block;
  font-size: 22px;
}

.menu .version {
  padding: 0;
  background: #F8F9FB;
}

.menu .version .number {
  color: #868686;
}

.menu.dark {
  background: #4A535A;
  border: none;
}

.menu.dark nav a {
  color: #95a0a8;
}

.menu.dark nav a.active {
  color: #FFF;
}

.menu.dark nav a i {
  color: #FFF;
}

.menu.dark nav a:hover {
  color: #FFF;
}

.menu.dark nav a:hover i {
  color: #FFF;
}

.menu.dark .version {
  background: #33393e;
}

.menu.dark .version .number {
  color: #FFF;
}

/***********************************
---- Site content ----
Tout sauf la Topbar et le menu
***********************************/
.site-content {
  margin-top: 45px;
  display: flex;
  height: calc(100vh - 45px);
}

.page {
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

/***********************************
---- Page content ----
entre le menu et la sidebar
***********************************/
.page-content {
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}

/***********************************
---- Sidebar ----
***********************************/
.sidebar {
  background: #F8F9FB;
  width: 275px;
  min-width: 275px;
  height: 100%;
  border-left: 1px solid #E4E8EB;
  box-sizing: border-box;
  overflow: auto;
}

.sidebar .sidebar-title {
  background: #0588d0;
  color: #FFF;
  margin: 0 0 10px 0;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  text-transform: uppercase;
}

.sidebar .sidebar-title:hover {
  cursor: pointer;
}

.sidebar.close {
  display: none;
}

/***********************************
---- PAGE AUTH ----
***********************************/
.auth-page {
  background-size: cover;
  background: url("../img/auth-background.jpg");
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 100px 20px 0 20px;
}

.auth-page .auth-panel {
  background: #FFF;
  height: 100vh;
  width: 290px;
  position: fixed;
  padding: 100px 20px;
  top: 0;
  right: 0;
}

.auth-page .auth-panel input {
  margin-bottom: 10px;
}

.auth-page .logo-app {
  width: 180px;
}

/***********************************
---- FOOTER ----
***********************************/
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  background: #0588d0;
  color: #FFF;
  text-align: center;
  font-size: 12px;
}

/***********************************
---- Responsive ----
***********************************/
@media only screen and (max-width: 1040px) {
  .site-content {
    display: block;
  }
  .page {
    display: block;
    height: auto;
  }
  .page-content {
    display: block;
    height: auto;
  }
  .menu {
    top: 45px;
    position: absolute;
    left: 0;
    z-index: 10;
  }
  .sidebar {
    position: absolute;
    top: 45px;
    right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .menu {
    width: 100%;
  }
  .sidebar {
    width: 100%;
  }
  .auth-page {
    background: none;
  }
  .auth-page .auth-panel {
    box-sizing: border-box;
    display: inline-block;
    background: #FFF;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px;
  }
}

/***********************************
---- Styles des btn ----
***********************************/
.btn {
  display: inline-block;
  padding: 10px 15px;
  margin: 0 5px;
  font-weight: 400;
  font-size: 13px;
  text-align: center;
  color: #868686;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  border-radius: 30px;
  border: 2px solid transparent;
  background: transparent;
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.2s;
}

.btn.full {
  width: 100%;
  border-radius: 5px;
}

.btn-bleu {
  background: #0588d0;
  border-color: #0588d0;
  color: #FFF;
}

.btn-bleu:hover {
  background: transparent;
  color: #0588d0;
}

.btn-bleu.btn-outline {
  background: transparent;
  color: #0588d0;
}

.btn-bleu.btn-outline:hover {
  background: #0588d0;
  color: #FFF;
}

.btn-vert {
  background: #3FC380;
  border-color: #3FC380;
  color: #FFF;
}

.btn-vert:hover {
  background: transparent;
  color: #3FC380;
}

.btn-vert.btn-outline {
  background: transparent;
  color: #3FC380;
}

.btn-vert.btn-outline:hover {
  background: #3FC380;
  color: #FFF;
}

.btn-rouge {
  background: #e74c3c;
  border-color: #e74c3c;
  color: #FFF;
}

.btn-rouge:hover {
  background: transparent;
  color: #e74c3c;
}

.btn-rouge.btn-outline {
  background: transparent;
  color: #e74c3c;
}

.btn-rouge.btn-outline:hover {
  background: #e74c3c;
  color: #FFF;
}

.btn-orange {
  background: #F29B34;
  border-color: #F29B34;
  color: #FFF;
}

.btn-orange:hover {
  background: transparent;
  color: #F29B34;
}

.btn-orange.btn-outline {
  background: transparent;
  color: #F29B34;
}

.btn-orange.btn-outline:hover {
  background: #F29B34;
  color: #FFF;
}

.btn-jaune {
  background: #F7CA18;
  border-color: #F7CA18;
  color: #FFF;
}

.btn-jaune:hover {
  background: transparent;
  color: #F7CA18;
}

.btn-jaune.btn-outline {
  background: transparent;
  color: #F7CA18;
}

.btn-jaune.btn-outline:hover {
  background: #F7CA18;
  color: #FFF;
}

.btn-fonce {
  background: #323232;
  border-color: #323232;
  color: #FFF;
}

.btn-fonce:hover {
  background: transparent;
  color: #323232;
}

.btn-fonce.btn-outline {
  background: transparent;
  color: #323232;
}

.btn-fonce.btn-outline:hover {
  background: #323232;
  color: #FFF;
}

.btn-sombre {
  background: #868686;
  border-color: #868686;
  color: #FFF;
}

.btn-sombre:hover {
  background: transparent;
  color: #868686;
}

.btn-sombre.btn-outline {
  background: transparent;
  color: #868686;
}

.btn-sombre.btn-outline:hover {
  background: #868686;
  color: #FFF;
}

.btn-noir {
  background: #000;
  border-color: #000;
  color: #FFF;
}

.btn-noir:hover {
  background: transparent;
  color: #000;
}

.btn-noir.btn-outline {
  background: transparent;
  color: #000;
}

.btn-noir.btn-outline:hover {
  background: #000;
  color: #FFF;
}

.btn-rose {
  background: #e76b8a;
  border-color: #e76b8a;
  color: #FFF;
}

.btn-rose:hover {
  background: transparent;
  color: #e76b8a;
}

.btn-rose.btn-outline {
  background: transparent;
  color: #e76b8a;
}

.btn-rose.btn-outline:hover {
  background: #e76b8a;
  color: #FFF;
}

.btn-violet {
  background: #7e4ee7;
  border-color: #7e4ee7;
  color: #FFF;
}

.btn-violet:hover {
  background: transparent;
  color: #7e4ee7;
}

.btn-violet.btn-outline {
  background: transparent;
  color: #7e4ee7;
}

.btn-violet.btn-outline:hover {
  background: #7e4ee7;
  color: #FFF;
}

.btn-cyan {
  background: #1eb4c3;
  border-color: #1eb4c3;
  color: #FFF;
}

.btn-cyan:hover {
  background: transparent;
  color: #1eb4c3;
}

.btn-cyan.btn-outline {
  background: transparent;
  color: #1eb4c3;
}

.btn-cyan.btn-outline:hover {
  background: #1eb4c3;
  color: #FFF;
}

.btn-gris {
  background: #E4E8EB;
  border-color: #E4E8EB;
  color: #FFF;
}

.btn-gris:hover {
  background: transparent;
  color: #E4E8EB;
}

.btn-gris.btn-outline {
  background: transparent;
  color: #E4E8EB;
}

.btn-gris.btn-outline:hover {
  background: #E4E8EB;
  color: #FFF;
}

button {
  font-size: 12px !important;
}

button.btn-small {
  font-size: 10px !important;
}

a.disable, button.disable {
  background: #DEE3E9 !important;
  border-color: #DEE3E9 !important;
  color: #E4E8EB !important;
  cursor: default;
}

a.disable:hover, button.disable:hover {
  pointer-events: none !important;
  cursor: default;
}

.btn-defaut {
  color: #0588d0;
}

.btn-defaut:hover {
  background: transparent;
}

.btn-small {
  font-size: 11px;
  text-transform: uppercase;
  border-width: 1px !important;
  padding: 5px 10px;
  margin: 0 2px;
}

.btn-horizontal {
  margin-top: 20px;
}

.add-item-list-btn {
  width: 100%;
  padding: 10px;
  background: transparent;
  border: 1px dashed #3FC380;
  border-radius: 5px;
  font-weight: 500;
  color: #3FC380;
}

.add-item-list-btn:hover {
  cursor: pointer;
}

/***********************************
---- Styles des formulaires ----
***********************************/
label {
  display: block;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 400;
}

label input {
  height: auto;
  width: auto;
}

.form-horizontal label, .form-horizontal input, .form-horizontal select, .form-horizontal textarea {
  display: inline;
}

.form-horizontal .field {
  width: 200px;
  display: inline-block;
}

input[type=text], input[type=password], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input, select, textarea {
  font-family: inherit;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: inherit;
  box-sizing: border-box;
  border: none;
  color: inherit;
}

input:focus, select:focus, textarea:focus {
  border-color: #0588d0;
}

input[type='password'], select[type='password'], textarea[type='password'] {
  letter-spacing: 4px;
}

input[type='password']::-webkit-input-placeholder, select[type='password']::-webkit-input-placeholder, textarea[type='password']::-webkit-input-placeholder {
  letter-spacing: 0px;
}

input[type='password']::-moz-placeholder, select[type='password']::-moz-placeholder, textarea[type='password']::-moz-placeholder {
  letter-spacing: 0px;
}

input:disabled, select:disabled, textarea:disabled {
  background: #F8F9FB;
  cursor: not-allowed;
  color: #E4E8EB;
}

select {
  -moz-appearance: none;
  background-image: url("../../img/icons/arrow.svg"), none;
  background-repeat: no-repeat;
  background-size: 1.5rem 1rem;
  background-position: right center;
  background-color: transparent !important;
  color: #000;
  color: transparent;
  text-shadow: 0 0 0 #000;
}

select option {
  color: #4A535A !important;
}

select[multiple] {
  background-image: none;
  height: auto;
}

textarea {
  min-height: 68px;
  resize: vertical;
}

input, textarea {
  background: transparent;
  border: none;
  border-radius: 0;
}

.field {
  position: relative;
  margin-bottom: 10px;
}

.field label {
  margin: 0;
}

.field::before {
  bottom: -1px;
  left: 0;
  width: 100%;
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  background: #E4E8EB;
}

.field::after {
  bottom: -1px;
  left: 0;
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 1px;
  background: #0588d0;
  transition: width .5s;
}

.field.focus::after {
  width: 100%;
}

.field.focus label {
  color: #0588d0 !important;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #939393;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #939393;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #939393;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #939393;
}

@media only screen and (max-width: 640px) {
  textarea, input, select {
    font-size: 16px !important;
  }
}

.editable-error {
  color: #e74c3c;
  margin-top: 5px;
}

/***********************************
---- ERREURS DE VALIDATION ----
***********************************/
.error .field label {
  color: #e74c3c;
}

.error .field::before {
  background: #e74c3c;
}

.error-message {
  display: inline-block;
  width: 100%;
  color: #e74c3c;
  margin-bottom: 5px;
}

/************************************************
---- FORMULAIRE HORIZONTAL APRES PAGE HEADER ----
*************************************************/
.create-form-horizontal {
  margin-top: 10px;
}

/***********************************
---- FORMULAIRE HORIZONTAL 2 ----
***********************************/
.form-h-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-h-button .form-h-button-input {
  width: 85%;
}

.form-h-button .form-h-button-actions {
  margin-left: auto;
}

/***********************************
---- FIX PICKADATE ----
***********************************/
.picker__select--year, .picker__select--month {
  font-size: 14px;
}

/***********************************
---- MULTI-SELECT ----
***********************************/
.multi-select {
  -moz-appearance: none;
  background-image: url("../../img/icons/arrow.svg"), none;
  background-repeat: no-repeat;
  background-size: 1.5rem 1rem;
  background-position: right center;
  background-color: transparent !important;
  color: #000;
  color: transparent;
  text-shadow: 0 0 0 #000;
  font-family: inherit;
  text-align: left;
  width: 100%;
  height: 40px;
  padding: 6px 12px;
  font-size: 14px !important;
  box-sizing: border-box;
  border: none;
}

.multi-select:focus {
  border-color: #0588d0;
}

.dropdown-menu-form {
  right: auto !important;
}

.dropdown-menu-form li a {
  padding: 5px 10px;
  display: block;
  width: 100%;
}

.dropdown-menu-form li a .glyphicon {
  color: #868686;
}

.dropdown-menu-form li a:hover {
  background: #0588d0;
  cursor: pointer;
  color: #FFF !important;
}

.dropdown-menu-form li a:hover .glyphicon {
  color: #FFF;
}

.dropdown-menu {
  -webkit-animation-duration: 0.5s !important;
  animation-duration: 0.5s !important;
  width: auto;
  min-width: 250px;
  line-height: normal;
  display: none;
  position: absolute;
  top: 45px;
  right: 5px;
  background: #FFF;
  border: 1px solid #E4E8EB;
  border-bottom: 2px solid #0588d0;
  z-index: 100;
}

.dropdown-menu .dropdown-menu-title {
  margin: 10px 0 5px 0;
  font-weight: 400;
  text-align: center;
}

.dropdown-menu .date {
  font-size: 12px;
  color: #868686;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu ul {
  text-align: left;
}

.dropdown-menu ul li {
  display: block !important;
}

.dropdown-menu ul li:last-child {
  border: none;
}

.dropdown-menu ul li:nth-child(even) {
  background: #F8F9FB;
}

.dropdown-menu ul li.active {
  background: #0588d0;
}

.dropdown-menu ul li.active a {
  color: #FFF;
}

.dropdown-menu ul li.active a:hover {
  color: #FFF;
}

.dropdown-menu ul li a {
  display: block;
  padding: 10px 20px !important;
  color: #323232;
}

.dropdown-menu ul li a:hover {
  color: #0588d0;
}

.dropdown-menu ul li a i {
  margin-right: 10px;
}

.dropdown-menu ul li.danger {
  background: rgba(231, 76, 60, 0.05);
}

.dropdown-menu ul li.danger a {
  color: #e74c3c;
}

.dropdown-icon .dropdown-item {
  font-size: 20px;
  color: #868686;
}

.dropdown-icon .dropdown-item:hover {
  color: #0588d0;
}

.dropdown-icon .dropdown-menu {
  top: -5px;
  right: 0px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.profil-avatar {
  display: inline-block;
  background-color: #E4E8EB;
  background-image: url("../img/avatars/default.png");
  width: 40px;
  height: 40px;
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  background-size: 88%;
  background-position: bottom center;
  background-repeat: no-repeat;
  border-radius: 50%;
}

.profil-avatar.small {
  width: 25px;
  height: 25px;
  min-width: 25px;
  max-width: 25px;
  max-height: 25px;
}

.profil-avatar.boy {
  background-image: url("../img/avatars/boy.png");
}

.profil-avatar.boy-1 {
  background-image: url("../img/avatars/boy-1.png");
}

.profil-avatar.boy-2 {
  background-image: url("../img/avatars/boy-2.png");
}

.profil-avatar.boy-3 {
  background-image: url("../img/avatars/boy-3.png");
}

.profil-avatar.boy-4 {
  background-image: url("../img/avatars/boy-4.png");
}

.profil-avatar.boy-5 {
  background-image: url("../img/avatars/boy-5.png");
}

.profil-avatar.boy-6 {
  background-image: url("../img/avatars/boy-6.png");
}

.profil-avatar.boy-7 {
  background-image: url("../img/avatars/boy-7.png");
}

.profil-avatar.boy-8 {
  background-image: url("../img/avatars/boy-8.png");
}

.profil-avatar.boy-9 {
  background-image: url("../img/avatars/boy-9.png");
}

.profil-avatar.boy-10 {
  background-image: url("../img/avatars/boy-10.png");
}

.profil-avatar.boy-11 {
  background-image: url("../img/avatars/boy-11.png");
}

.profil-avatar.boy-12 {
  background-image: url("../img/avatars/boy-12.png");
}

.profil-avatar.boy-13 {
  background-image: url("../img/avatars/boy-13.png");
}

.profil-avatar.boy-14 {
  background-image: url("../img/avatars/boy-14.png");
}

.profil-avatar.boy-15 {
  background-image: url("../img/avatars/boy-15.png");
}

.profil-avatar.boy-16 {
  background-image: url("../img/avatars/boy-16.png");
}

.profil-avatar.boy-17 {
  background-image: url("../img/avatars/boy-17.png");
}

.profil-avatar.boy-18 {
  background-image: url("../img/avatars/boy-18.png");
}

.profil-avatar.boy-19 {
  background-image: url("../img/avatars/boy-19.png");
}

.profil-avatar.boy-20 {
  background-image: url("../img/avatars/boy-20.png");
}

.profil-avatar.boy-21 {
  background-image: url("../img/avatars/boy-21.png");
}

.profil-avatar.boy-22 {
  background-image: url("../img/avatars/boy-22.png");
}

.profil-avatar.girl {
  background-image: url("../img/avatars/girl.png");
}

.profil-avatar.girl-1 {
  background-image: url("../img/avatars/girl-1.png");
}

.profil-avatar.girl-2 {
  background-image: url("../img/avatars/girl-2.png");
}

.profil-avatar.girl-3 {
  background-image: url("../img/avatars/girl-3.png");
}

.profil-avatar.girl-4 {
  background-image: url("../img/avatars/girl-4.png");
}

.profil-avatar.girl-5 {
  background-image: url("../img/avatars/girl-5.png");
}

.profil-avatar.girl-6 {
  background-image: url("../img/avatars/girl-6.png");
}

.profil-avatar.girl-7 {
  background-image: url("../img/avatars/girl-7.png");
}

.profil-avatar.girl-8 {
  background-image: url("../img/avatars/girl-8.png");
}

.profil-avatar.girl-9 {
  background-image: url("../img/avatars/girl-9.png");
}

.profil-avatar.girl-10 {
  background-image: url("../img/avatars/girl-10.png");
}

.profil-avatar.girl-11 {
  background-image: url("../img/avatars/girl-11.png");
}

.profil-avatar.girl-12 {
  background-image: url("../img/avatars/girl-12.png");
}

.profil-avatar.girl-13 {
  background-image: url("../img/avatars/girl-13.png");
}

.profil-avatar.girl-14 {
  background-image: url("../img/avatars/girl-14.png");
}

.profil-avatar.girl-15 {
  background-image: url("../img/avatars/girl-15.png");
}

.profil-avatar.girl-16 {
  background-image: url("../img/avatars/girl-16.png");
}

.profil-avatar.girl-17 {
  background-image: url("../img/avatars/girl-17.png");
}

.profil-avatar.girl-18 {
  background-image: url("../img/avatars/girl-18.png");
}

.profil-avatar.girl-19 {
  background-image: url("../img/avatars/girl-19.png");
}

.profil-avatar.girl-20 {
  background-image: url("../img/avatars/girl-20.png");
}

.profil-avatar.girl-21 {
  background-image: url("../img/avatars/girl-21.png");
}

.profil-avatar.girl-22 {
  background-image: url("../img/avatars/girl-22.png");
}

.profil-avatar.girl-23 {
  background-image: url("../img/avatars/girl-23.png");
}

.profil-avatar.girl-24 {
  background-image: url("../img/avatars/girl-24.png");
}

.profil-avatar.girl-25 {
  background-image: url("../img/avatars/girl-25.png");
}

.profil-avatar.girl-26 {
  background-image: url("../img/avatars/girl-26.png");
}

.card {
  width: 400px;
  min-width: 400px;
  border-right: 1px solid #E4E8EB;
  overflow: auto;
}

.card .card-header {
  padding: 10px;
  text-align: center;
  background: #F8F9FB;
  border-bottom: 1px solid #E4E8EB;
}

.card .card-header.bleu {
  background: #0588d0;
  color: #FFF;
}

.card .card-header.vert {
  background: #3FC380;
  color: #FFF;
}

.card .card-header.rouge {
  background: #e74c3c;
  color: #FFF;
}

.card .card-header.orange {
  background: #F29B34;
  color: #FFF;
}

.card .card-header.jaune {
  background: #F7CA18;
  color: #FFF;
}

.card .card-header.fonce {
  background: #323232;
  color: #FFF;
}

.card .card-header.sombre {
  background: #868686;
  color: #FFF;
}

.card .card-header.noir {
  background: #000;
  color: #FFF;
}

.card .card-header.rose {
  background: #e76b8a;
  color: #FFF;
}

.card .card-header.violet {
  background: #7e4ee7;
  color: #FFF;
}

.card .card-header.cyan {
  background: #1eb4c3;
  color: #FFF;
}

.card .card-header.gris {
  background: #E4E8EB;
  color: #4A535A;
}

.card .card-header .card-icon {
  font-size: 30px;
}

.card .card-header .card-title {
  font-weight: 300;
  font-size: 16px;
  margin-top: 10px;
  text-transform: uppercase;
}

.card .card-header .card-update {
  font-size: 12px;
  font-weight: 300;
  margin-top: 5px;
}

.card .card-bloc {
  padding: 10px 5px;
}

.card .card-bloc .card-bloc-title {
  font-size: 17px;
  margin-left: 10px;
}

.card .card-list {
  width: 100%;
}

.card .card-list tr {
  border-bottom: 1px solid #E4E8EB;
}

.card .card-list tr td:first-child {
  color: #868686;
}

.card .card-list tr td {
  padding: 15px 10px;
}

.card .card-list tr td input, .card .card-list tr td select {
  height: 30px;
}

.card .card-list input {
  background: #E4E8EB;
}

.card .card-actions {
  margin-top: 10px;
  text-align: center;
}

.card .card-actions a {
  color: #868686;
}

@media only screen and (max-width: 1040px) {
  .card {
    display: block;
    border: none;
    width: 100%;
    min-width: 100%;
  }
}

.comments .comment {
  display: flex;
  margin-bottom: 10px;
  padding: 5px 0;
  border-bottom: 1px solid #F8F9FB;
}

.comments .comment:last-child {
  border: none;
}

.comments .comment .comment-profil {
  line-height: 45px;
  width: 45px;
  height: 45px;
  display: inline-block;
  margin-right: 10px;
}

.comments .comment .comment-content {
  display: inline-block;
  width: 80%;
}

.comments .comment .comment-content .comment-auteur {
  font-weight: 400;
}

.comments .comment .comment-content .comment-associated {
  font-style: italic;
  margin-top: 5px;
}

.comments .comment .comment-content .comment-message {
  line-height: 22px;
  font-weight: 400;
}

.comments .comment .comment-content .edit-field {
  margin-top: 5px;
}

.comments .comment .comment-content .comment-date {
  color: #868686;
  display: block;
  margin-top: 5px;
  text-transform: capitalize;
}

.comments .comment .comment-content .task-associate {
  background: #F8F9FB;
  color: #4A535A;
  display: inline-block;
  font-weight: 400;
  padding: 5px 10px;
  margin: 5px 0;
}

.comments .comment .comment-content .task-associate:hover {
  color: #0588d0;
}

.comments .comment .comment-actions {
  margin-left: auto;
}

.comments .comment .comment-actions a {
  margin-left: 5px;
  color: #868686;
  font-size: 18px;
}

.comments .comment .comment-actions a:hover {
  color: #0588d0;
}

.comments .comment .comment-actions i {
  vertical-align: middle;
}

.comment-row td {
  padding: 0 15px 10px 15px !important;
  color: #868686;
}

.information li {
  display: flex;
  align-items: center;
}

.information .information-title {
  font-weight: 400;
  width: 180px;
}

.information .information-content {
  margin-left: 15px;
  width: 100%;
}

.information li {
  padding: 15px 10px;
}

.information li.row {
  margin: 0;
}

.information li:nth-child(even) {
  background: #F8F9FB;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  text-align: center;
}

.loader {
  margin-top: 15px;
  display: inline-block;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 3px solid #E4E8EB;
  border-top-color: #0588d0;
  animation: spin 1s infinite linear;
}

.logs {
  margin: 20px 0;
}

.logs .logs-date {
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: 400;
}

.logs .logs-liste li {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
}

.logs .logs-liste li:nth-child(odd) {
  background: #F8F9FB;
}

.logs .logs-liste a {
  font-weight: 400;
}

.logs .logs-liste .log-date {
  display: block;
  color: #868686;
  margin-top: 5px;
}

.modal {
  position: fixed;
  display: none;
  z-index: 2000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal .modal-header, .modal .modal-body, .modal .modal-footer {
  padding: 20px;
}

.modal .modal-header {
  padding-bottom: 0px;
}

.modal .modal-header .modal-title {
  font-weight: 400;
  font-size: 18px;
  text-align: center;
}

.modal .modal-header .modal-close {
  position: absolute;
  right: 10px;
  top: 10px;
  color: #868686;
}

.modal .modal-content {
  background-color: #fefefe;
  margin: 2% auto;
  border: 1px solid #888;
  width: 65%;
  border-bottom: 2px solid #0588d0;
  position: relative;
}

@media only screen and (max-width: 640px) {
  .modal .modal-content {
    width: 90% !important;
    margin: 5% auto !important;
  }
}

@media only screen and (max-width: 1040px) {
  .modal .modal-content {
    width: 95% !important;
  }
}

.modal .modal-footer {
  background: #F8F9FB;
}

.pagination {
  margin: 20px 0;
  text-align: center;
}

.pagination li {
  display: inline-block;
}

.pagination li a {
  display: block;
  padding: 10px 15px;
  font-size: 14px;
}

.pagination li a:hover {
  background: #F8F9FB;
}

.pagination li.disabled a {
  color: #E4E8EB;
}

.pagination li.disabled a:hover {
  background: transparent;
  cursor: not-allowed;
}

.pagination li.active a {
  background: #0588d0;
  color: #FFF;
}

.panel .panel-header {
  padding: 20px 20px 0px 20px;
}

.panel .panel-header .panel-title {
  font-size: 20px;
  font-weight: 400;
}

.panel .panel-header .panel-subtitle {
  font-size: 14px;
  color: #868686;
  display: block;
  margin-top: 5px;
}

.panel .panel-body {
  padding: 20px 10px;
}

.panel .panel-footer {
  padding: 10px;
  background: #F8F9FB;
}

@media only screen and (max-width: 640px) {
  .panel-body {
    padding: 10px 0 !important;
  }
  .panel-header {
    padding: 10px 10px 0px 10px !important;
  }
}

.stats-well {
  text-align: right;
}

.stats-well .stats-number {
  font-size: 22px;
}

.well-bleu {
  background: #0588d0 !important;
  color: #FFF;
}

.well-vert {
  background: #3FC380 !important;
  color: #FFF;
}

.well-rouge {
  background: #e74c3c !important;
  color: #FFF;
}

.well-orange {
  background: #F29B34 !important;
  color: #FFF;
}

.well-jaune {
  background: #F7CA18 !important;
  color: #FFF;
}

.well-fonce {
  background: #323232 !important;
  color: #FFF;
}

.well-sombre {
  background: #868686 !important;
  color: #FFF;
}

.well-noir {
  background: #000 !important;
  color: #FFF;
}

.well-rose {
  background: #e76b8a !important;
  color: #FFF;
}

.well-violet {
  background: #7e4ee7 !important;
  color: #FFF;
}

.well-cyan {
  background: #1eb4c3 !important;
  color: #FFF;
}

.well-gris {
  background: #E4E8EB !important;
  color: #4A535A;
}

.table {
  width: 100%;
}

.table th {
  font-weight: 300;
  text-align: left;
  color: #868686;
}

.table th, .table td {
  padding: 10px 15px;
  vertical-align: middle;
}

.table td input {
  height: auto;
}

.table thead {
  border-bottom: 1px solid #E4E8EB;
}

.table tbody tr {
  border-bottom: 1px solid #E4E8EB;
}

.table tbody tr:hover {
  background: #F8F9FB;
}

.table-interactions tbody:hover {
  background: #F8F9FB;
}

.tabs {
  background: #F8F9FB;
  border-bottom: 1px solid #E4E8EB;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
}

.tabs > a {
  color: #868686;
  font-weight: 500;
  display: inline-block;
  margin: 0 5px;
  padding: 20px 10px;
}

.tabs > a.active {
  border-bottom: 3px solid #0588d0;
  color: #0588d0;
}

.tabs > a:hover {
  border-bottom: 3px solid #0588d0;
}

.tabs .actions {
  margin-top: 10px;
  margin-left: auto;
}

.tabs-menu {
  display: block;
  background: #4A535A;
  color: #95a0a8;
  text-transform: uppercase;
  padding: 15px 10px;
}

@media only screen and (max-width: 640px) {
  .tabs {
    flex-direction: column;
  }
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}

.tag-bleu, .tag-vert, .tag-rouge, .tag-orange, .tag-jaune, .tag-fonce, .tag-sombre, .tag-noir, .tag-rose, .tag-violet, .tag-cyan, .tag-gris {
  display: inline-block;
  padding: 3px 6px;
  margin: 0 2px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  user-select: none;
  border-radius: 30px;
  font-size: 11px;
  text-transform: uppercase;
}

.tag-bleu {
  background: #0588d0;
  color: #FFF;
}

.tag-vert {
  background: #3FC380;
  color: #FFF;
}

.tag-rouge {
  background: #e74c3c;
  color: #FFF;
}

.tag-orange {
  background: #F29B34;
  color: #FFF;
}

.tag-jaune {
  background: #F7CA18;
  color: #FFF;
}

.tag-fonce {
  background: #323232;
  color: #FFF;
}

.tag-sombre {
  background: #868686;
  color: #FFF;
}

.tag-noir {
  background: #000;
  color: #FFF;
}

.tag-rose {
  background: #e76b8a;
  color: #FFF;
}

.tag-violet {
  background: #7e4ee7;
  color: #FFF;
}

.tag-cyan {
  background: #1eb4c3;
  color: #FFF;
}

.tag-gris {
  background: #E4E8EB;
  color: #4A535A;
}

.tasks-list .task-list-item {
  padding: 15px 20px;
  border-bottom: 1px solid #E4E8EB;
  display: flex;
  align-items: center;
}

.tasks-list .task-list-item:hover {
  background: #F8F9FB !important;
}

.tasks-list .task-list-item.archived {
  background: repeating-linear-gradient(-45deg, #F8F9FB, #F8F9FB 10px, white 10px, white 20px);
}

.tasks-list .task-list-item.important {
  background: repeating-linear-gradient(-45deg, #fdf3f2, #fdf3f2 10px, white 10px, white 20px);
}

.tasks-list .task-list-item .task-list-item-content {
  display: inline-block;
}

.task-designation {
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
}

.task-designation:hover {
  cursor: pointer;
}

a.task-designation {
  color: #4A535A;
}

.task-description {
  line-height: 22px;
}

.task-description:hover {
  cursor: pointer;
}

.task-company {
  display: block;
  margin-top: 5px;
}

.task-company a {
  font-weight: 400;
}

.task-state {
  display: inline-block;
  padding: 5px;
  height: 25px;
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  max-height: 25px;
  line-height: 30px;
  background: #E4E8EB;
  border-radius: 50%;
  margin-right: 20px;
  color: #FFF;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  position: relative;
}

.task-state::after {
  font-family: "Ionicons";
  position: absolute;
  font-size: 15px;
  top: 3px;
  left: 11px;
}

.task-state.todo {
  color: #4A535A;
}

.task-state.todo::after {
  content: '\f117';
}

.task-state.progress {
  background: #0588d0;
}

.task-state.progress::after {
  content: '\f488';
  left: 15px;
}

.task-state.done {
  background: #3FC380;
}

.task-state.done::after {
  content: '\f122';
  left: 12px;
}

.task-users-list {
  display: inline-block;
  padding: 0 30px;
}

.task-users-list .profil-avatar {
  width: 30px;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  max-height: 30px;
  margin-right: -5px;
}

.task-user {
  display: flex;
  align-items: center;
}

.task-user .icon-link {
  margin-left: auto;
  font-size: 14px;
}

.task-users {
  margin-right: 20px;
}

.task-right-content {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.task-actions {
  display: inline-block;
  width: 140px;
  text-align: right;
}

.task-actions.reduced {
  width: 30px;
}

.task-date {
  margin-left: auto;
  text-align: center;
  display: inline-block;
  padding: 0 20px;
}

.task-date .task-date-day {
  display: block;
  font-size: 20px;
  font-weight: 400;
}

.task-date .task-date-month {
  text-transform: uppercase;
}

.task-alert-date {
  color: #e74c3c;
}

.task {
  padding-top: 5px !important;
}

.task .task-dates {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.task .task-dates .task-date {
  margin: 0;
  padding: 0 20px 0 0;
}

.task .task-dates .task-actions {
  margin-left: auto;
}

.task .task-users-list {
  margin-left: auto;
  padding: 0;
}

.task-header {
  display: flex;
  align-items: center;
}

.task-header .task-header-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.task-section {
  margin-top: 20px;
}

.task-section .task-section-title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 400;
}

.task-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: stretch;
  border-bottom: 1px solid #E4E8EB;
  margin-bottom: 15px;
}

.task-tabs a {
  padding: 0 10px 20px 10px;
  font-weight: 400;
  color: #868686;
}

.task-tabs a:hover {
  color: #0588d0;
}

.task-tabs a.active {
  color: #0588d0;
  border-bottom: 2px solid #0588d0;
}

/***********************************
---- CONTACTS ----
***********************************/
.contact {
  background: #F8F9FB;
  padding: 15px;
  border-radius: 5px;
  line-height: 20px;
}

.contact .contact-fonction {
  color: #0588d0;
  text-transform: uppercase;
}

.contact .contact-nom {
  font-size: 16px;
  font-weight: 400;
}

.well-actions {
  float: right;
}

.well-actions a {
  margin-left: 5px;
  color: #868686;
  font-size: 18px;
}

.well-actions a:hover {
  color: #0588d0;
}

.well-actions i {
  vertical-align: middle;
}

/***********************************
---- ADRESSES ----
***********************************/
.well {
  background: #F8F9FB;
  padding: 15px;
  border-radius: 5px;
  line-height: 20px;
}

.well .well-title {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 400;
}

.well .well-subtitle {
  display: block;
  color: #868686;
  text-transform: uppercase;
  font-size: 12px;
}

/***********************************
---- ALERT ----
***********************************/
.alert {
  box-sizing: border-box;
  padding: 20px 15px;
  width: 100%;
  background: #868686;
}

.alert strong {
  font-weight: 600;
}

.alert.alert-bleu {
  background: #0588d0;
  color: #FFF;
}

.alert.alert-vert {
  background: #3FC380;
  color: #FFF;
}

.alert.alert-rouge {
  background: #e74c3c;
  color: #FFF;
}

.alert.alert-orange {
  background: #F29B34;
  color: #FFF;
}

.alert.alert-jaune {
  background: #F7CA18;
  color: #FFF;
}

.alert.alert-fonce {
  background: #323232;
  color: #FFF;
}

.alert.alert-sombre {
  background: #868686;
  color: #FFF;
}

.alert.alert-noir {
  background: #000;
  color: #FFF;
}

.alert.alert-rose {
  background: #e76b8a;
  color: #FFF;
}

.alert.alert-violet {
  background: #7e4ee7;
  color: #FFF;
}

.alert.alert-cyan {
  background: #1eb4c3;
  color: #FFF;
}

.alert.alert-gris {
  background: #E4E8EB;
  color: #FFF;
}

.alert a {
  color: #FFF;
  font-style: italic;
  text-decoration: underline;
}

/***********************************
---- BADGES ----
***********************************/
.badge {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.badge.bleu {
  background: #0588d0;
}

.badge.vert {
  background: #3FC380;
}

.badge.rouge {
  background: #e74c3c;
}

.badge.orange {
  background: #F29B34;
}

.badge.jaune {
  background: #F7CA18;
}

.badge.fonce {
  background: #323232;
}

.badge.sombre {
  background: #868686;
}

.badge.noir {
  background: #000;
}

.badge.rose {
  background: #e76b8a;
}

.badge.violet {
  background: #7e4ee7;
}

.badge.cyan {
  background: #1eb4c3;
}

.badge.gris {
  background: #E4E8EB;
}

/***********************************
---- Statistiques sur tâches ----
***********************************/
.tasks-stats {
  margin: 20px 0;
}

.tasks-stats .well {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tasks-stats .tasks-stats-right {
  margin-left: auto;
  text-align: right;
}

.tasks-stats .tasks-stats-number {
  font-size: 22px;
}

/***********************************
---- TYPEHEAD ----
***********************************/
.uib-typeahead-match {
  padding: 5px 10px;
}

.uib-typeahead-match:hover {
  background: #0588d0;
  cursor: pointer;
}

.uib-typeahead-match:hover a {
  color: #FFF !important;
}

/***********************************
---- LISTE USERS ADMIN ----
***********************************/
.admin-users-list {
  margin-top: 20px;
}

.admin-users-list .user-well {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.admin-users-list .user-well .user-well-content {
  margin-left: 10px;
}

.admin-users-list .user-well .user-well-actions {
  margin-left: auto;
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url("../fonts/glyphicons-halflings-regular.eot");
  src: url("../fonts/glyphicons-halflings-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/glyphicons-halflings-regular.woff2") format("woff2"), url("../fonts/glyphicons-halflings-regular.woff") format("woff"), url("../fonts/glyphicons-halflings-regular.ttf") format("truetype"), url("../fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-asterisk:before {
  content: "\002a";
}

.glyphicon-plus:before {
  content: "\002b";
}

.glyphicon-euro:before,
.glyphicon-eur:before {
  content: "\20ac";
}

.glyphicon-minus:before {
  content: "\2212";
}

.glyphicon-cloud:before {
  content: "\2601";
}

.glyphicon-envelope:before {
  content: "\2709";
}

.glyphicon-pencil:before {
  content: "\270f";
}

.glyphicon-glass:before {
  content: "\e001";
}

.glyphicon-music:before {
  content: "\e002";
}

.glyphicon-search:before {
  content: "\e003";
}

.glyphicon-heart:before {
  content: "\e005";
}

.glyphicon-star:before {
  content: "\e006";
}

.glyphicon-star-empty:before {
  content: "\e007";
}

.glyphicon-user:before {
  content: "\e008";
}

.glyphicon-film:before {
  content: "\e009";
}

.glyphicon-th-large:before {
  content: "\e010";
}

.glyphicon-th:before {
  content: "\e011";
}

.glyphicon-th-list:before {
  content: "\e012";
}

.glyphicon-ok:before {
  content: "\e013";
}

.glyphicon-remove:before {
  content: "\e014";
}

.glyphicon-zoom-in:before {
  content: "\e015";
}

.glyphicon-zoom-out:before {
  content: "\e016";
}

.glyphicon-off:before {
  content: "\e017";
}

.glyphicon-signal:before {
  content: "\e018";
}

.glyphicon-cog:before {
  content: "\e019";
}

.glyphicon-trash:before {
  content: "\e020";
}

.glyphicon-home:before {
  content: "\e021";
}

.glyphicon-file:before {
  content: "\e022";
}

.glyphicon-time:before {
  content: "\e023";
}

.glyphicon-road:before {
  content: "\e024";
}

.glyphicon-download-alt:before {
  content: "\e025";
}

.glyphicon-download:before {
  content: "\e026";
}

.glyphicon-upload:before {
  content: "\e027";
}

.glyphicon-inbox:before {
  content: "\e028";
}

.glyphicon-play-circle:before {
  content: "\e029";
}

.glyphicon-repeat:before {
  content: "\e030";
}

.glyphicon-refresh:before {
  content: "\e031";
}

.glyphicon-list-alt:before {
  content: "\e032";
}

.glyphicon-lock:before {
  content: "\e033";
}

.glyphicon-flag:before {
  content: "\e034";
}

.glyphicon-headphones:before {
  content: "\e035";
}

.glyphicon-volume-off:before {
  content: "\e036";
}

.glyphicon-volume-down:before {
  content: "\e037";
}

.glyphicon-volume-up:before {
  content: "\e038";
}

.glyphicon-qrcode:before {
  content: "\e039";
}

.glyphicon-barcode:before {
  content: "\e040";
}

.glyphicon-tag:before {
  content: "\e041";
}

.glyphicon-tags:before {
  content: "\e042";
}

.glyphicon-book:before {
  content: "\e043";
}

.glyphicon-bookmark:before {
  content: "\e044";
}

.glyphicon-print:before {
  content: "\e045";
}

.glyphicon-camera:before {
  content: "\e046";
}

.glyphicon-font:before {
  content: "\e047";
}

.glyphicon-bold:before {
  content: "\e048";
}

.glyphicon-italic:before {
  content: "\e049";
}

.glyphicon-text-height:before {
  content: "\e050";
}

.glyphicon-text-width:before {
  content: "\e051";
}

.glyphicon-align-left:before {
  content: "\e052";
}

.glyphicon-align-center:before {
  content: "\e053";
}

.glyphicon-align-right:before {
  content: "\e054";
}

.glyphicon-align-justify:before {
  content: "\e055";
}

.glyphicon-list:before {
  content: "\e056";
}

.glyphicon-indent-left:before {
  content: "\e057";
}

.glyphicon-indent-right:before {
  content: "\e058";
}

.glyphicon-facetime-video:before {
  content: "\e059";
}

.glyphicon-picture:before {
  content: "\e060";
}

.glyphicon-map-marker:before {
  content: "\e062";
}

.glyphicon-adjust:before {
  content: "\e063";
}

.glyphicon-tint:before {
  content: "\e064";
}

.glyphicon-edit:before {
  content: "\e065";
}

.glyphicon-share:before {
  content: "\e066";
}

.glyphicon-check:before {
  content: "\e067";
}

.glyphicon-move:before {
  content: "\e068";
}

.glyphicon-step-backward:before {
  content: "\e069";
}

.glyphicon-fast-backward:before {
  content: "\e070";
}

.glyphicon-backward:before {
  content: "\e071";
}

.glyphicon-play:before {
  content: "\e072";
}

.glyphicon-pause:before {
  content: "\e073";
}

.glyphicon-stop:before {
  content: "\e074";
}

.glyphicon-forward:before {
  content: "\e075";
}

.glyphicon-fast-forward:before {
  content: "\e076";
}

.glyphicon-step-forward:before {
  content: "\e077";
}

.glyphicon-eject:before {
  content: "\e078";
}

.glyphicon-chevron-left:before {
  content: "\e079";
}

.glyphicon-chevron-right:before {
  content: "\e080";
}

.glyphicon-plus-sign:before {
  content: "\e081";
}

.glyphicon-minus-sign:before {
  content: "\e082";
}

.glyphicon-remove-sign:before {
  content: "\e083";
}

.glyphicon-ok-sign:before {
  content: "\e084";
}

.glyphicon-question-sign:before {
  content: "\e085";
}

.glyphicon-info-sign:before {
  content: "\e086";
}

.glyphicon-screenshot:before {
  content: "\e087";
}

.glyphicon-remove-circle:before {
  content: "\e088";
}

.glyphicon-ok-circle:before {
  content: "\e089";
}

.glyphicon-ban-circle:before {
  content: "\e090";
}

.glyphicon-arrow-left:before {
  content: "\e091";
}

.glyphicon-arrow-right:before {
  content: "\e092";
}

.glyphicon-arrow-up:before {
  content: "\e093";
}

.glyphicon-arrow-down:before {
  content: "\e094";
}

.glyphicon-share-alt:before {
  content: "\e095";
}

.glyphicon-resize-full:before {
  content: "\e096";
}

.glyphicon-resize-small:before {
  content: "\e097";
}

.glyphicon-exclamation-sign:before {
  content: "\e101";
}

.glyphicon-gift:before {
  content: "\e102";
}

.glyphicon-leaf:before {
  content: "\e103";
}

.glyphicon-fire:before {
  content: "\e104";
}

.glyphicon-eye-open:before {
  content: "\e105";
}

.glyphicon-eye-close:before {
  content: "\e106";
}

.glyphicon-warning-sign:before {
  content: "\e107";
}

.glyphicon-plane:before {
  content: "\e108";
}

.glyphicon-calendar:before {
  content: "\e109";
}

.glyphicon-random:before {
  content: "\e110";
}

.glyphicon-comment:before {
  content: "\e111";
}

.glyphicon-magnet:before {
  content: "\e112";
}

.glyphicon-chevron-up:before {
  content: "\e113";
}

.glyphicon-chevron-down:before {
  content: "\e114";
}

.glyphicon-retweet:before {
  content: "\e115";
}

.glyphicon-shopping-cart:before {
  content: "\e116";
}

.glyphicon-folder-close:before {
  content: "\e117";
}

.glyphicon-folder-open:before {
  content: "\e118";
}

.glyphicon-resize-vertical:before {
  content: "\e119";
}

.glyphicon-resize-horizontal:before {
  content: "\e120";
}

.glyphicon-hdd:before {
  content: "\e121";
}

.glyphicon-bullhorn:before {
  content: "\e122";
}

.glyphicon-bell:before {
  content: "\e123";
}

.glyphicon-certificate:before {
  content: "\e124";
}

.glyphicon-thumbs-up:before {
  content: "\e125";
}

.glyphicon-thumbs-down:before {
  content: "\e126";
}

.glyphicon-hand-right:before {
  content: "\e127";
}

.glyphicon-hand-left:before {
  content: "\e128";
}

.glyphicon-hand-up:before {
  content: "\e129";
}

.glyphicon-hand-down:before {
  content: "\e130";
}

.glyphicon-circle-arrow-right:before {
  content: "\e131";
}

.glyphicon-circle-arrow-left:before {
  content: "\e132";
}

.glyphicon-circle-arrow-up:before {
  content: "\e133";
}

.glyphicon-circle-arrow-down:before {
  content: "\e134";
}

.glyphicon-globe:before {
  content: "\e135";
}

.glyphicon-wrench:before {
  content: "\e136";
}

.glyphicon-tasks:before {
  content: "\e137";
}

.glyphicon-filter:before {
  content: "\e138";
}

.glyphicon-briefcase:before {
  content: "\e139";
}

.glyphicon-fullscreen:before {
  content: "\e140";
}

.glyphicon-dashboard:before {
  content: "\e141";
}

.glyphicon-paperclip:before {
  content: "\e142";
}

.glyphicon-heart-empty:before {
  content: "\e143";
}

.glyphicon-link:before {
  content: "\e144";
}

.glyphicon-phone:before {
  content: "\e145";
}

.glyphicon-pushpin:before {
  content: "\e146";
}

.glyphicon-usd:before {
  content: "\e148";
}

.glyphicon-gbp:before {
  content: "\e149";
}

.glyphicon-sort:before {
  content: "\e150";
}

.glyphicon-sort-by-alphabet:before {
  content: "\e151";
}

.glyphicon-sort-by-alphabet-alt:before {
  content: "\e152";
}

.glyphicon-sort-by-order:before {
  content: "\e153";
}

.glyphicon-sort-by-order-alt:before {
  content: "\e154";
}

.glyphicon-sort-by-attributes:before {
  content: "\e155";
}

.glyphicon-sort-by-attributes-alt:before {
  content: "\e156";
}

.glyphicon-unchecked:before {
  content: "\e157";
}

.glyphicon-expand:before {
  content: "\e158";
}

.glyphicon-collapse-down:before {
  content: "\e159";
}

.glyphicon-collapse-up:before {
  content: "\e160";
}

.glyphicon-log-in:before {
  content: "\e161";
}

.glyphicon-flash:before {
  content: "\e162";
}

.glyphicon-log-out:before {
  content: "\e163";
}

.glyphicon-new-window:before {
  content: "\e164";
}

.glyphicon-record:before {
  content: "\e165";
}

.glyphicon-save:before {
  content: "\e166";
}

.glyphicon-open:before {
  content: "\e167";
}

.glyphicon-saved:before {
  content: "\e168";
}

.glyphicon-import:before {
  content: "\e169";
}

.glyphicon-export:before {
  content: "\e170";
}

.glyphicon-send:before {
  content: "\e171";
}

.glyphicon-floppy-disk:before {
  content: "\e172";
}

.glyphicon-floppy-saved:before {
  content: "\e173";
}

.glyphicon-floppy-remove:before {
  content: "\e174";
}

.glyphicon-floppy-save:before {
  content: "\e175";
}

.glyphicon-floppy-open:before {
  content: "\e176";
}

.glyphicon-credit-card:before {
  content: "\e177";
}

.glyphicon-transfer:before {
  content: "\e178";
}

.glyphicon-cutlery:before {
  content: "\e179";
}

.glyphicon-header:before {
  content: "\e180";
}

.glyphicon-compressed:before {
  content: "\e181";
}

.glyphicon-earphone:before {
  content: "\e182";
}

.glyphicon-phone-alt:before {
  content: "\e183";
}

.glyphicon-tower:before {
  content: "\e184";
}

.glyphicon-stats:before {
  content: "\e185";
}

.glyphicon-sd-video:before {
  content: "\e186";
}

.glyphicon-hd-video:before {
  content: "\e187";
}

.glyphicon-subtitles:before {
  content: "\e188";
}

.glyphicon-sound-stereo:before {
  content: "\e189";
}

.glyphicon-sound-dolby:before {
  content: "\e190";
}

.glyphicon-sound-5-1:before {
  content: "\e191";
}

.glyphicon-sound-6-1:before {
  content: "\e192";
}

.glyphicon-sound-7-1:before {
  content: "\e193";
}

.glyphicon-copyright-mark:before {
  content: "\e194";
}

.glyphicon-registration-mark:before {
  content: "\e195";
}

.glyphicon-cloud-download:before {
  content: "\e197";
}

.glyphicon-cloud-upload:before {
  content: "\e198";
}

.glyphicon-tree-conifer:before {
  content: "\e199";
}

.glyphicon-tree-deciduous:before {
  content: "\e200";
}

.glyphicon-cd:before {
  content: "\e201";
}

.glyphicon-save-file:before {
  content: "\e202";
}

.glyphicon-open-file:before {
  content: "\e203";
}

.glyphicon-level-up:before {
  content: "\e204";
}

.glyphicon-copy:before {
  content: "\e205";
}

.glyphicon-paste:before {
  content: "\e206";
}

.glyphicon-alert:before {
  content: "\e209";
}

.glyphicon-equalizer:before {
  content: "\e210";
}

.glyphicon-king:before {
  content: "\e211";
}

.glyphicon-queen:before {
  content: "\e212";
}

.glyphicon-pawn:before {
  content: "\e213";
}

.glyphicon-bishop:before {
  content: "\e214";
}

.glyphicon-knight:before {
  content: "\e215";
}

.glyphicon-baby-formula:before {
  content: "\e216";
}

.glyphicon-tent:before {
  content: "\26fa";
}

.glyphicon-blackboard:before {
  content: "\e218";
}

.glyphicon-bed:before {
  content: "\e219";
}

.glyphicon-apple:before {
  content: "\f8ff";
}

.glyphicon-erase:before {
  content: "\e221";
}

.glyphicon-hourglass:before {
  content: "\231b";
}

.glyphicon-lamp:before {
  content: "\e223";
}

.glyphicon-duplicate:before {
  content: "\e224";
}

.glyphicon-piggy-bank:before {
  content: "\e225";
}

.glyphicon-scissors:before {
  content: "\e226";
}

.glyphicon-bitcoin:before {
  content: "\e227";
}

.glyphicon-btc:before {
  content: "\e227";
}

.glyphicon-xbt:before {
  content: "\e227";
}

.glyphicon-yen:before {
  content: "\00a5";
}

.glyphicon-jpy:before {
  content: "\00a5";
}

.glyphicon-ruble:before {
  content: "\20bd";
}

.glyphicon-rub:before {
  content: "\20bd";
}

.glyphicon-scale:before {
  content: "\e230";
}

.glyphicon-ice-lolly:before {
  content: "\e231";
}

.glyphicon-ice-lolly-tasted:before {
  content: "\e232";
}

.glyphicon-education:before {
  content: "\e233";
}

.glyphicon-option-horizontal:before {
  content: "\e234";
}

.glyphicon-option-vertical:before {
  content: "\e235";
}

.glyphicon-menu-hamburger:before {
  content: "\e236";
}

.glyphicon-modal-window:before {
  content: "\e237";
}

.glyphicon-oil:before {
  content: "\e238";
}

.glyphicon-grain:before {
  content: "\e239";
}

.glyphicon-sunglasses:before {
  content: "\e240";
}

.glyphicon-text-size:before {
  content: "\e241";
}

.glyphicon-text-color:before {
  content: "\e242";
}

.glyphicon-text-background:before {
  content: "\e243";
}

.glyphicon-object-align-top:before {
  content: "\e244";
}

.glyphicon-object-align-bottom:before {
  content: "\e245";
}

.glyphicon-object-align-horizontal:before {
  content: "\e246";
}

.glyphicon-object-align-left:before {
  content: "\e247";
}

.glyphicon-object-align-vertical:before {
  content: "\e248";
}

.glyphicon-object-align-right:before {
  content: "\e249";
}

.glyphicon-triangle-right:before {
  content: "\e250";
}

.glyphicon-triangle-left:before {
  content: "\e251";
}

.glyphicon-triangle-bottom:before {
  content: "\e252";
}

.glyphicon-triangle-top:before {
  content: "\e253";
}

.glyphicon-console:before {
  content: "\e254";
}

.glyphicon-superscript:before {
  content: "\e255";
}

.glyphicon-subscript:before {
  content: "\e256";
}

.glyphicon-menu-left:before {
  content: "\e257";
}

.glyphicon-menu-right:before {
  content: "\e258";
}

.glyphicon-menu-down:before {
  content: "\e259";
}

.glyphicon-menu-up:before {
  content: "\e260";
}

/***********************************
---- TOOLTIP ----
***********************************/
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto;
}

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: .9;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
