.stats-well{
	text-align: right;

	.stats-number{
		font-size: 22px;
	}
}

@each $label, $couleur in $couleurs {
	.well-#{$label}{
		background: $couleur!important;
		@if $label == 'gris' {
			color: $dark;
		} @else {
			color: #FFF;
		}
	}
}