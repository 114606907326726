.table{
	width: 100%;
	th{
		font-weight: 300;
		text-align: left;
		color: $sombre;
	}
	th,td{
		padding: 10px 15px;
		vertical-align: middle;
	}
	td{
		input{
			height: auto;
		}
	}
	thead{ border-bottom: 1px solid $gris; }
	tbody{
		tr{
			border-bottom: 1px solid $gris;
			&:hover{
				background: $clair;
			}
		}
	}
}

.table-interactions{
	tbody{
		&:hover{ background: $clair; }
	}
}