/***********************************
---- Fonts ----
***********************************/
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700');

/***********************************
---- Règles générales ----
***********************************/
*{
    outline:none;
    &:focus{ outline:none; }
    &:active{ outline:none; }
}

body{
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: $fonce;
    background: #FFF;
    width: 100%;
    height: 100%;
    &.modal-open{
        overflow: hidden;
        position: fixed;
    }
}

@media only screen and (max-width: $s-width) {
    body.menu-open {
        overflow: hidden;
        position: fixed;
    }
}

a{
    color: $main;
    text-decoration: none;
    &.lien-icon{
        color: $sombre;
        padding: 5px 10px;
        border: 1px solid $sombre;
        border-radius: 30px;
        &:hover{
            border-color: $main;
            color: $main;
        }
    }
}

i{ color: inherit; }

strong{ font-weight: 600; }

.brand{
    text-align: center;
    margin: 0 auto 40px auto;
    display: block;
    text-transform: uppercase;
    color: $fonce;
    font-size: 16px;
    font-weight: 400;

    img{ width: 130px; }

    .name{
        color: $main;
        font-weight: 600;
    }
}

.page-header{
    background: $clair;
    border-bottom: 1px solid $gris;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .page-title{
        color: $main;
        font-size: 20px;
        font-weight: 300;
    }

    .page-actions{
        margin-left: auto;
    }
}

/***********************************
---- Helper Classes ----
***********************************/
.pull-right{ float: right; }
.pull-left{ float: left; }

.text-center{ text-align: center; }
.text-right{ text-align: right; }
.text-left{ text-align: left; }

.help-text{
    color: $sombre;
    font-size: 13px;
    margin: 10px 0;
}

.no-border{ border: none!important; }

.show{ display: block; }

.no-data{
    text-align: center;
    color: $sombre;
    margin: 20px 0;
    font-size: 15px;
    text-transform: uppercase;
}

.no-data-more{
    display: block;
    font-size: 11px;
    margin-top: 10px;
}

.date-format{ text-transform: capitalize; }

/***********************************
---- PAGE D'ERREUR ----
***********************************/
.page-error{
    text-align: center;
    .page-error-code{
        font-size: 40px;
        color: $sombre;
        margin-bottom: 30px;
        font-weight: 300;
    }
    .page-error-message{
        font-size: 30px;
        margin-bottom: 10px;
    }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.icon-link{
    font-size: 18px;
    color: $sombre;
    margin-right: 5px;
    position: relative;
    &:hover{ color: $main; }
}

.tranfer-icon{
    color: $sombre;
    font-size: 30px;
    margin: 0 20px;
    vertical-align: middle;
}

.hide{ display: none; }
.border-bottom{ border-bottom: 1px solid $gris; }
.no-border-bottom{ border-bottom: none!important; }