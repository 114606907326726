/***********************************
---- Styles des btn ----
***********************************/
.btn{
    display: inline-block;
    padding: 10px 15px;
    margin: 0 5px;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    color: $sombre;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    border-radius: 30px;
    border: 2px solid transparent;
    background: transparent;
    cursor: pointer;

    transition-property: all;
    transition-duration: $default-transition-duration;

    &.full{
        width: 100%;
        border-radius: 5px;
    }
}

// Mixin permettant la création d'un btn a partir d'un label et d'une couleur
@mixin generation-btn($label: '', $couleur: '') {
    .btn-#{$label}{
        background: $couleur;
        border-color: $couleur;
        color: #FFF;

        &:hover{
            background: transparent;
            color: $couleur;
        }

        &.btn-outline{
            background: transparent;
            color: $couleur;
            &:hover{
                background: $couleur;
                color: #FFF;
            }
        }
    }
}

// Génération des btn à partir de la liste des couleurs @variables.scss
@each $label, $couleur in $couleurs {
    @include generation-btn($label, $couleur);
}

// Ajustement des classes génériques
button{
    font-size: 12px!important;
    &.btn-small{ font-size: 10px!important; }
}

a.disable, button.disable{
    background: $gris-clair!important;
    border-color: $gris-clair!important;
    color: $gris!important;
    cursor: default;
    &:hover{
        pointer-events: none!important;
        cursor: default;
    }
}

.btn-defaut{
    color: $bleu;

    &:hover{ background: transparent; }
}

.btn-small{
    font-size: 11px;
    text-transform: uppercase;
    border-width: 1px!important;
    padding: 5px 10px;
    margin: 0 2px;
}

.btn-horizontal{ margin-top: 20px; }

.add-item-list-btn{
    width: 100%;
    padding: 10px;
    background: transparent;
    border: 1px dashed $vert;
    border-radius: 5px;
    font-weight: 500;
    color: $vert;

    &:hover{ cursor: pointer; }
}

/***********************************
---- Styles des formulaires ----
***********************************/
// LABELS
label{
    display: block;
    margin-bottom: 5px;
    cursor: pointer;
    font-weight: 400;
    input{
        height: auto;
        width: auto;
    }
}

.form-horizontal{
    label, input, select, textarea{
        display: inline;
    }
    .field{
        width: 200px;
        display: inline-block;
    }

}

// INPUTS
input[type=text], input[type=password], textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input, select, textarea{
    font-family: inherit;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: inherit;
    box-sizing: border-box;
    border: none;
    color: inherit;

    &:focus{ border-color: $main; }

    &[type='password']{
        letter-spacing: 4px;
        &::-webkit-input-placeholder{ letter-spacing: 0px; }
        &::-moz-placeholder{ letter-spacing: 0px; }
    }
}

input, select, textarea{
    &:disabled{
        background: $clair;
        cursor: not-allowed;
        color: $gris;
    }
}

select{
    -moz-appearance: none;
    background-image: url("../../img/icons/arrow.svg"), none;
    background-repeat: no-repeat;
    background-size: 1.5rem 1rem;
    background-position: right center;
    background-color: transparent!important;

    option{
        color: $dark!important;
    }

    color: #000;
    color: rgba(0,0,0,0);
    text-shadow: 0 0 0 #000;

    &[multiple] {
        background-image: none;
        height: auto;
    }
}

textarea{
    min-height: 68px;
    resize: vertical;
}

input, textarea{
    background: transparent;
    border: none;
    border-radius: 0;
}

.field{
    position: relative;
    margin-bottom: 10px;

    label{
        margin: 0;
    }

    &::before{
        bottom: -1px;
        left: 0;
        width: 100%;
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        background: $gris
    }
    &::after{
        bottom: -1px;
        left: 0;
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 1px;
        background: $main;
        transition: width .5s;
    }

    &.focus{
        &::after{
            width: 100%;
        }
        label{
            color: $main!important;
        }
    }
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    lighten($sombre, 5);
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color:    lighten($sombre, 5);
    opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    color:    lighten($sombre, 5);
    opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color:    lighten($sombre, 5);
}

@media only screen and (max-width: $s-width) {
    textarea, input, select{
        font-size: 16px!important;
    }
}

// XEDITABLE
.editable-error{
    color: $rouge;
    margin-top: 5px;
}

/***********************************
---- ERREURS DE VALIDATION ----
***********************************/
.error{
    .field{
        label{ color: $rouge; }
        &::before{ background: $rouge; }
    }
}

.error-message{
    display: inline-block;
    width: 100%;
    color: $rouge;
    margin-bottom: 5px;
}

/************************************************
---- FORMULAIRE HORIZONTAL APRES PAGE HEADER ----
*************************************************/
.create-form-horizontal{
    margin-top: 10px;
}

/***********************************
---- FORMULAIRE HORIZONTAL 2 ----
***********************************/
.form-h-button{
    display: flex;
    flex-direction: row;
    align-items: center;

    .form-h-button-input{
        width: 85%;
    }

    .form-h-button-actions{
        margin-left: auto;
    }
}

/***********************************
---- FIX PICKADATE ----
***********************************/
.picker__select--year, .picker__select--month{
    font-size: 14px;
}

/***********************************
---- MULTI-SELECT ----
***********************************/
.multi-select{
    -moz-appearance: none;
    background-image: url("../../img/icons/arrow.svg"), none;
    background-repeat: no-repeat;
    background-size: 1.5rem 1rem;
    background-position: right center;
    background-color: transparent!important;

    color: #000;
    color: rgba(0,0,0,0);
    text-shadow: 0 0 0 #000;

    font-family: inherit;
    text-align: left;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px!important;
    box-sizing: border-box;
    border: none;

    &:focus{ border-color: $main; }
}

.dropdown-menu-form{
    right: auto!important;
    li{
        a{
            padding: 5px 10px;
            display: block;
            width: 100%;
            .glyphicon{ color: $sombre; }

            &:hover{
                background: $main;
                cursor: pointer;
                color: #FFF!important;

                .glyphicon{ color: #FFF; }
            }
        }
    }
}

.dropdown-menu{
    -webkit-animation-duration: .5s!important;
    animation-duration: .5s!important;
    width: auto;
    min-width: 250px;
    line-height: normal;
    display: none;
    position: absolute;
    top: $hauteurTopBar;
    right: 5px;
    background: #FFF;
    border: 1px solid $gris;
    border-bottom: 2px solid $main;
    z-index: 100;

    .dropdown-menu-title{
        margin: 10px 0 5px 0;
        font-weight: 400;
        text-align: center;
    }

    .date{
        font-size: 12px;
        color: $sombre;
    }

    &.show{ display: block; }

    ul{
        text-align: left;
        li{
            display: block!important;
            &:last-child{border: none;}
            &:nth-child(even){ background: $clair; }
            &.active{
                background: $main;
                a{
                    color: #FFF;
                    &:hover{ color: #FFF; }
                }
            }
            a{
                display: block;
                padding: 10px 20px!important;
                color: $fonce;
                &:hover{
                    color: $main;
                }
                i{margin-right: 10px;}
            }

            &.danger{
                background: rgba($rouge, .05);
                a{
                    color: $rouge;
                }
            }
        }
    }
}

.dropdown-icon{
    .dropdown-item{
        font-size: 20px;
        color: $sombre;
        &:hover{ color: $main; }
    }
    .dropdown-menu{
        top: -5px;
        right: 0px;
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}