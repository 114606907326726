$taille-avatar-principal: 40px;
$taille-avatar-small: 25px;

.profil-avatar {
	display: inline-block;
	background-color: $gris;
	background-image: url('../img/avatars/default.png');
	width: $taille-avatar-principal;
	height: $taille-avatar-principal;
	min-width: $taille-avatar-principal;
	max-width: $taille-avatar-principal;
	max-height: $taille-avatar-principal;
	background-size: 88%;
	background-position: bottom center;
	background-repeat: no-repeat;
	border-radius: 50%;

	&.small{
		width: $taille-avatar-small;
		height: $taille-avatar-small;
		min-width: $taille-avatar-small;
		max-width: $taille-avatar-small;
		max-height: $taille-avatar-small;
	}

	&.boy{ background-image: url('../img/avatars/boy.png'); }
	&.boy-1{ background-image: url('../img/avatars/boy-1.png'); }
	&.boy-2{ background-image: url('../img/avatars/boy-2.png'); }
	&.boy-3{ background-image: url('../img/avatars/boy-3.png'); }
	&.boy-4{ background-image: url('../img/avatars/boy-4.png'); }
	&.boy-5{ background-image: url('../img/avatars/boy-5.png'); }
	&.boy-6{ background-image: url('../img/avatars/boy-6.png'); }
	&.boy-7{ background-image: url('../img/avatars/boy-7.png'); }
	&.boy-8{ background-image: url('../img/avatars/boy-8.png'); }
	&.boy-9{ background-image: url('../img/avatars/boy-9.png'); }
	&.boy-10{ background-image: url('../img/avatars/boy-10.png'); }
	&.boy-11{ background-image: url('../img/avatars/boy-11.png'); }
	&.boy-12{ background-image: url('../img/avatars/boy-12.png'); }
	&.boy-13{ background-image: url('../img/avatars/boy-13.png'); }
	&.boy-14{ background-image: url('../img/avatars/boy-14.png'); }
	&.boy-15{ background-image: url('../img/avatars/boy-15.png'); }
	&.boy-16{ background-image: url('../img/avatars/boy-16.png'); }
	&.boy-17{ background-image: url('../img/avatars/boy-17.png'); }
	&.boy-18{ background-image: url('../img/avatars/boy-18.png'); }
	&.boy-19{ background-image: url('../img/avatars/boy-19.png'); }
	&.boy-20{ background-image: url('../img/avatars/boy-20.png'); }
	&.boy-21{ background-image: url('../img/avatars/boy-21.png'); }
	&.boy-22{ background-image: url('../img/avatars/boy-22.png'); }

	&.girl{ background-image: url('../img/avatars/girl.png'); }
	&.girl-1{ background-image: url('../img/avatars/girl-1.png'); }
	&.girl-2{ background-image: url('../img/avatars/girl-2.png'); }
	&.girl-3{ background-image: url('../img/avatars/girl-3.png'); }
	&.girl-4{ background-image: url('../img/avatars/girl-4.png'); }
	&.girl-5{ background-image: url('../img/avatars/girl-5.png'); }
	&.girl-6{ background-image: url('../img/avatars/girl-6.png'); }
	&.girl-7{ background-image: url('../img/avatars/girl-7.png'); }
	&.girl-8{ background-image: url('../img/avatars/girl-8.png'); }
	&.girl-9{ background-image: url('../img/avatars/girl-9.png'); }
	&.girl-10{ background-image: url('../img/avatars/girl-10.png'); }
	&.girl-11{ background-image: url('../img/avatars/girl-11.png'); }
	&.girl-12{ background-image: url('../img/avatars/girl-12.png'); }
	&.girl-13{ background-image: url('../img/avatars/girl-13.png'); }
	&.girl-14{ background-image: url('../img/avatars/girl-14.png'); }
	&.girl-15{ background-image: url('../img/avatars/girl-15.png'); }
	&.girl-16{ background-image: url('../img/avatars/girl-16.png'); }
	&.girl-17{ background-image: url('../img/avatars/girl-17.png'); }
	&.girl-18{ background-image: url('../img/avatars/girl-18.png'); }
	&.girl-19{ background-image: url('../img/avatars/girl-19.png'); }
	&.girl-20{ background-image: url('../img/avatars/girl-20.png'); }
	&.girl-21{ background-image: url('../img/avatars/girl-21.png'); }
	&.girl-22{ background-image: url('../img/avatars/girl-22.png'); }
	&.girl-23{ background-image: url('../img/avatars/girl-23.png'); }
	&.girl-24{ background-image: url('../img/avatars/girl-24.png'); }
	&.girl-25{ background-image: url('../img/avatars/girl-25.png'); }
	&.girl-26{ background-image: url('../img/avatars/girl-26.png'); }
}