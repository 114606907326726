.logs{
	margin: 20px 0;

	.logs-date{
		padding: 10px 20px;
		text-transform: uppercase;
		font-weight: 400;
	}

	.logs-liste{
		li{
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 15px 30px;
			&:nth-child(odd){ background: $clair; }
		}

		a{ font-weight: 400; }
		.log-date{
			display: block;
			color: $sombre;
			margin-top: 5px;
		}
	}
}