.tasks-list{
	.task-list-item{
		padding: 15px 20px;
		border-bottom: 1px solid $gris;
		display: flex;
		align-items: center;

		&:hover{ background: $clair!important; }

		&.archived{  background: repeating-linear-gradient(-45deg, $clair, $clair 10px, white 10px, white 20px); }
		&.important{  background: repeating-linear-gradient(-45deg, lighten($rouge, 40), lighten($rouge, 40) 10px, white 10px, white 20px); }

		.task-list-item-content{
			display: inline-block;
		}
	}
}

.task-designation{
	font-weight: 400;
	font-size: 15px;
	line-height: 20px;
	&:hover{ cursor: pointer; }
}
a.task-designation{
	color: $dark;
}

.task-description{
	line-height: 22px;
	&:hover{ cursor: pointer; }
}

.task-company{
	display: block;
	margin-top: 5px;
	a{ font-weight: 400; }
}

$task-state-size: 25px;
.task-state{
	display: inline-block;
	padding: 5px;
	height: $task-state-size;
	width: $task-state-size;
	min-width: $task-state-size;
	max-width: $task-state-size;
	max-height: $task-state-size;
	line-height: 30px;
	background: $gris;
	border-radius: 50%;
	margin-right: 20px;
	color: #FFF;
	font-weight: 400;
	text-transform: uppercase;
	text-align: center;
	font-size: 20px;
	position: relative;

	&::after{
		font-family: "Ionicons";
		position: absolute;
		font-size: 15px;
		top: 3px;
		left: 11px;
	}

	&.todo{
		&::after{ content: '\f117'; }
		color: $dark;
	}
	&.progress{
		&::after{ content: '\f488'; left: 15px; }
		background: $bleu;
	}
	&.done{
		&::after{ content: '\f122'; left: 12px; }
		background: $vert;
	}
}

$taille-avatar-task : 30px;
.task-users-list{
	display: inline-block;
	padding: 0 30px;
	.profil-avatar{
		width: $taille-avatar-task;
		height: $taille-avatar-task;
		min-width: $taille-avatar-task;
		max-width: $taille-avatar-task;
		max-height: $taille-avatar-task;
		margin-right: -5px;
	}
}
.task-user{
	display: flex;
	align-items: center;

	.icon-link{
		margin-left: auto;
		font-size: 14px;
	}
}

.task-users{
	margin-right: 20px;
}

.task-right-content{
	display: flex;
	align-items: center;
	margin-left: auto;
}

.task-actions{
	display: inline-block;
	width: 140px;
	text-align: right;
	&.reduced{ width: 30px; }
}

.task-date{
	margin-left: auto;
	text-align: center;
	display: inline-block;
	padding: 0 20px;
	.task-date-day{
		display: block;
		font-size: 20px;
		font-weight: 400;
	}
	.task-date-month{ text-transform: uppercase; }
}

.task-alert-date{  color: $rouge; }

.task{
	padding-top: 5px!important;
	.task-dates{
		display: flex;
		align-items: center;
		margin-top: 20px;

		.task-date{
			margin: 0;
			padding: 0 20px 0 0;
		}

		.task-actions{ margin-left: auto; }
	}
	.task-users-list{
		margin-left: auto;
		padding: 0;
	}
}

.task-header{
	display: flex;
	align-items: center;
	.task-header-content{
		display: flex;
		flex-direction: column;
		width: 100%;
	}
}

.task-section{
	margin-top: 20px;
	.task-section-title{
		margin-bottom: 10px;
		font-size: 15px;
		font-weight: 400;
	}
}

.task-tabs{
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-content: stretch;
	border-bottom: 1px solid $gris;
	margin-bottom: 15px;
	a{
		padding: 0 10px 20px 10px;
		font-weight: 400;
		color: $sombre;
		&:hover{ color: $main; }
		&.active{
			color: $main;
			border-bottom: 2px solid $main;
		}
	}
}

@media only screen and (max-width: $s-width) {
	.task-list-item{
		//text-align: left;
		//flex-direction: column;
	}
}