.information{
	li{
		display: flex;
		align-items: center;
	}
	.information-title{
		font-weight: 400;
		width: 180px;
	}
	.information-content{
		margin-left: 15px;
		width: 100%;
	}
	li{
		padding: 15px 10px;
		&.row{
			margin: 0;
		}
		&:nth-child(even){
			background: $clair;
		}
	}
}