
@import "components/avatar";
@import "components/cards";
@import "components/comments";
@import "components/listes";
@import "components/loader";
@import "components/logs";
@import "components/modals";
@import "components/pagination";
@import "components/panels";
@import "components/stats";
@import "components/tables";
@import "components/tabs";
@import "components/tags";
@import "components/tasks";

/***********************************
---- CONTACTS ----
***********************************/
.contact{
    background: $clair;
    padding: 15px;
    border-radius: 5px;
    line-height: 20px;

    .contact-fonction{
        color: $main;
        text-transform: uppercase;
    }

    .contact-nom{
        font-size: 16px;
        font-weight: 400;
    }
}

.well-actions{
    float: right;
    a{
        margin-left: 5px;
        color: $sombre;
        font-size: 18px;
        &:hover{ color: $main; }
    }
    i{
        vertical-align: middle;
    }
}

/***********************************
---- ADRESSES ----
***********************************/
.well{
    background: $clair;
    padding: 15px;
    border-radius: 5px;
    line-height: 20px;

    .well-title{
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 400;
    }
    .well-subtitle{
        display: block;
        color: $sombre;
        text-transform: uppercase;
        font-size: 12px;
    }
}

/***********************************
---- ALERT ----
***********************************/
.alert{
    box-sizing: border-box;
    padding: 20px 15px;
    width: 100%;
    background: $sombre;
    strong{ font-weight: 600; }
    @each $label, $couleur in $couleurs {
        &.alert-#{$label}{
            background: $couleur;
            color: #FFF;
        }
    }

    a{
        color: #FFF;
        font-style: italic;
        text-decoration: underline;
    }
}

/***********************************
---- BADGES ----
***********************************/
.badge{
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;

    @each $label, $couleur in $couleurs {
        &.#{$label}{ background: $couleur; }
    }
}

/***********************************
---- Statistiques sur tâches ----
***********************************/

.tasks-stats{
    margin: 20px 0;

    .well{
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .tasks-stats-right{
        margin-left: auto;
        text-align: right;
    }

    .tasks-stats-number{ font-size: 22px; }

}

/***********************************
---- TYPEHEAD ----
***********************************/
.uib-typeahead-match{
    padding: 5px 10px;
    &:hover{
        background: $main;
        cursor: pointer;
        a{ color: #FFF!important; }
    }
}

/***********************************
---- LISTE USERS ADMIN ----
***********************************/
.admin-users-list{
    margin-top: 20px;
    .user-well{
        display: flex;
        flex-direction: row;
        align-items: center;
        .user-well-content{
            margin-left: 10px;
        }

        .user-well-actions{
            margin-left: auto;
        }
    }
}