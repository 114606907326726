.pagination{
	margin: 20px 0;
	text-align: center;

	li{
		display: inline-block;
		a{
			display: block;
			padding: 10px 15px;
			font-size: 14px;
			&:hover{
				background: $clair;
			}
		}
		&.disabled{
			a{
				color: $gris;
				&:hover{
					background: transparent;
					cursor: not-allowed;
				}
			}
		}
		&.active{
			a{
				background: $main;
				color: #FFF;
			}
		}
	}
}