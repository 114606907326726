/***********************************
---- Variables ----
***********************************/
$colonnes   : 12!default;
$espacement : 10px!default;
$m-width    : 1040px!default;
$s-width    : 640px!default;

/***********************************
---- Règles générales ----
***********************************/
%col{
    float: left;
    position: relative;
    min-height: 1px;
    padding: 0 $espacement;
    margin-bottom: $espacement;
    box-sizing: border-box;
}

.row{
    margin-bottom: 5px;
    @import 'clearfix';
}

// Mixin de création des classes
@mixin responsive-colonnes($suffix: '') {
    @for $i from 0 through $colonnes {
        @if $i != 0 {
            .col#{$suffix}-#{$i}{ width: $i / $colonnes * 100%; }
        }
        .col#{$suffix}-push-#{$i}{ left: $i / $colonnes *100%; }
        .col#{$suffix}-offset-#{$i}{ margin-left: $i / $colonnes *100%; }
    }
    .col#{$suffix}-center{ margin: 0 auto; float: none; }
}

// Toutes les colonnes héritent de %col
@for $i from 1 through $colonnes {
    .col-s-#{$i}, .col-m-#{$i}, col-l-#{$i}{ @extend %col; }
}

/***********************************
---- Responsives ----
***********************************/
// Génération des classes mobiles
@include responsive-colonnes('-s');
// Génération des classes tablettes
@media only screen and (min-width: $s-width) {
    @include responsive-colonnes('-m');
}
// Génération des classes grands écrans
@media only screen and (min-width: $m-width) {
    @include responsive-colonnes('-l');
}

// Création des classes hide
.col-l-hide{ display: none; }
@media only screen and (max-width: $s-width) {
    .col-s-hide{ display: none!important; }
}
@media only screen and (max-width: $m-width) {
    .col-m-hide{ display: none; }
}

// Création des classes show
.col-s-show{ display: none!important; }
@media only screen and (max-width: $s-width) {
    .col-s-show{ display: block!important; }
}