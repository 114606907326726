.modal{
	position: fixed;
	display: none;
	z-index: 2000;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: scroll;
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);

	.modal-header, .modal-body, .modal-footer{
		padding: 20px;
	}

	.modal-header{
		padding-bottom: 0px;

		.modal-title{
			font-weight: 400;
			font-size: 18px;
			text-align: center;
		}

		.modal-close{
			position: absolute;
			right: 10px;
			top: 10px;
			color: $sombre;
		}
	}

	.modal-content{
		background-color: #fefefe;
		margin: 2% auto;
		border: 1px solid #888;
		width: 65%;
		border-bottom: 2px solid $main;
		position: relative;
	}

	@media only screen and (max-width: $s-width) {
		.modal-content{
			width: 90%!important;
			margin: 5% auto!important;
		}
	}

	@media only screen and (max-width: $m-width) {
		.modal-content{ width: 95%!important; }
	}

	.modal-footer{
		background: $clair;
	}
}