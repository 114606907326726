/***********************************
---- Variables ----
***********************************/
$hauteurTopBar  : 45px;
$largeurMenu    : 110px;
$largeurSidebar : 275px;
$largeurCard    : 400px;

/***********************************
---- Topbar ----
***********************************/
.topbar{
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #FFF;
    border-bottom: 1px solid $gris;
    height: $hauteurTopBar;
    line-height: $hauteurTopBar;
    text-align: center;
    color: $fonce;

    .brand{
        display: inline-block;
        img{
            width: 100px;
            vertical-align: middle;
        }
    }

    .topbar-menu{
        position: absolute;
        top: 0;
        left: 0;
        width: 80px;
        height: $hauteurTopBar;
        border-radius: 50%;
        %bar{
            position: absolute;
            display: block;
            background: $main;
            width: 22px;
            height: 2px;
            transition: all $default-transition-duration;
        }
        p{ margin-left: 45px; }
        span{
            @extend %bar;
            top: 50%;
            left: 35%;
            transform: translateX(-50%) translateY(-50%);

            &::before, &::after{
                content: '';
                @extend %bar;
                top: 0;
                left: 0;
                transform: translateY(-6px);
                transform-origin: 50% 50%;
            }
            &::after{ transform: translateY(6px); }
        }
        &:hover{
            cursor: pointer;
            span::before{ transform: translateY(-8px); }
            span::after{ transform: translateY(8px); }
        }

        &.open{
            span{
                background: transparent;
                &::before{ transform: translateY(0) rotate(45deg); }
                &::after{ transform: translateY(0) rotate(-45deg); }
                &::before, &::after{ transform-origin: 50% 50%; }
            }
        }
    }

    .topbar-actions{
        position: absolute;
        top: 0;
        right: 20px;

        &>ul{
            &>li{
                position: relative;
                display: inline-block;

                &>a{
                    padding: 0 15px;
                    position: relative;
                    &>i{
                        vertical-align: middle;
                        font-size: 22px;
                        color: $sombre;
                    }
                    &>i.drop-down{
                        color: $main;
                        font-size: inherit;
                    }
                }
            }
        }
    }

    .profil-avatar{
        vertical-align: middle;
        margin-right: 5px;
    }
}

/***********************************
---- Menu ----
***********************************/
.menu{
    background: #FFF;
    width: $largeurMenu;
    min-width: $largeurMenu;
    height: 100%;
    color: $sombre;
    border-right: 1px solid $gris;
    text-align: center;
    box-sizing: border-box;
    transition-property: margin-left;
    transition-duration: $default-transition-duration;
    overflow: auto;

    &.close{ display: none; }

    nav{
        display: flex;
        flex-direction: column;
        a{
            position: relative;
            display: block;
            padding: 10px 0;
            margin: 5px 0;
            width: 100%;
            line-height: 22px;
            font-weight: 400;
            font-size: 14px;
            color: $sombre;
            &.active{
                color: $dark;
                &::after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 4px;
                    height: 100%;
                    background: $main;
                }
                i{
                    color: $main;
                }
            }
            &:hover{
                color: $main;
                i{color: $main;}
            }
            i{
                display: block;
                font-size: 22px;
            }
        }
    }

    .version{
        padding: 0;
        background: $clair;
        .number{color: $sombre;}
    }

    &.dark{
        background: $dark;
        border: none;

        nav{
            a{
                color: lighten($dark, 30);
                &.active{
                    color: #FFF;
                }
                i{ color: #FFF; }
                &:hover{
                    color: #FFF;
                    i{ color: #FFF; }
                }
            }
        }

        .version{
            background: darken($dark, 10);
            .number{color: #FFF;}
        }
    }
}

/***********************************
---- Site content ----
Tout sauf la Topbar et le menu
***********************************/
.site-content{
    margin-top: $hauteurTopBar;
    display: flex;
    height: calc(100vh - 45px);
}

.page{
    display: flex;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}

/***********************************
---- Page content ----
entre le menu et la sidebar
***********************************/
.page-content{
    box-sizing: border-box;
    flex-grow: 1;
    height: 100%;
    overflow: auto;
}

/***********************************
---- Sidebar ----
***********************************/
.sidebar{
    background: $clair;
    width: $largeurSidebar;
    min-width: $largeurSidebar;
    height: 100%;
    border-left: 1px solid $gris;
    box-sizing: border-box;
    overflow: auto;

    .sidebar-title{
        background: $main;
        color: #FFF;
        margin: 0 0 10px 0;
        padding: 10px;
        font-size: 16px;
        font-weight: 300;
        text-transform: uppercase;
        &:hover{ cursor: pointer; }
    }

    &.close{ display: none; }
}

/***********************************
---- PAGE AUTH ----
***********************************/
.auth-page{
	background-size: cover;
    background: url('../img/auth-background.jpg');
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding: 100px 20px 0 20px;

    .auth-panel{
		background: #FFF;
		height: 100vh;
        width: 290px;
        position: fixed;
		padding: 100px 20px;
		top: 0;
		right: 0;
        input{
            margin-bottom: 10px;
        }
    }

    .logo-app{
        width: 180px;
    }
}

/***********************************
---- FOOTER ----
***********************************/
footer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 5px;
    background: $main;
    color: #FFF;
    text-align: center;
    font-size: 12px;
}

/***********************************
---- Responsive ----
***********************************/
@media only screen and (max-width: $m-width) {
    .site-content{ display: block; }
    .page{ display: block; height: auto; }
    .page-content{ display: block; height: auto; }
    .menu{
        top: $hauteurTopBar;
        position: absolute;
        left: 0;
        z-index: 10;
    }
    .sidebar{
        position: absolute;
        top: $hauteurTopBar;
        right: 0;
    }
}
@media only screen and (max-width: $s-width) {
    .menu{ width: 100%; }
    .sidebar{ width: 100%; }

	.auth-page{
		background: none;

		.auth-panel{
			box-sizing: border-box;
			display: inline-block;
			background: #FFF;
			left: 0;
			top: 0;
			width: 100%;
			padding: 20px;
		}
	}
}