.comments{
	.comment{
		display: flex;
		margin-bottom: 10px;
		padding: 5px 0;
		border-bottom: 1px solid $clair;
		&:last-child{border: none;}

		$taille-avatar-commentaire: 45px;
		.comment-profil{
			line-height: $taille-avatar-commentaire;
			width: $taille-avatar-commentaire;
			height: $taille-avatar-commentaire;
			display: inline-block;
			margin-right: 10px;
		}

		.comment-content{
			display: inline-block;
			width: 80%;
			.comment-auteur{ font-weight: 400; }

			.comment-associated{
				font-style: italic;
				margin-top: 5px;
			}

			.comment-message{
				line-height: 22px;
				font-weight: 400;
			}
			.edit-field{ margin-top: 5px; }
			.comment-date{
				color: $sombre;
				display: block;
				margin-top: 5px;
				text-transform: capitalize;
			}

			.task-associate{
				background: $clair;
				color: $dark;
				display: inline-block;
				font-weight: 400;
				padding: 5px 10px;
				margin: 5px 0;
				&:hover{ color: $main; }
			}
		}

		.comment-actions{
			margin-left: auto;
			a{
				margin-left: 5px;
				color: $sombre;
				font-size: 18px;
				&:hover{ color: $main; }
			}
			i{
				vertical-align: middle;
			}
		}
	}
}

.comment-row{
	td{
		padding: 0 15px 10px 15px !important;
		color: $sombre;
	}
}