@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loading{
	text-align: center;
}

.loader {
	margin-top: 15px;
	display: inline-block;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border: 3px solid $gris;
	border-top-color: $main;
	animation: spin 1s infinite linear;
}