%tag{
	display: inline-block;
	padding: 3px 6px;
	margin: 0 2px;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	user-select: none;
	border-radius: 30px;
	font-size: 11px;
	text-transform: uppercase;
}

@mixin generation-tag($label: '', $couleur: '') {
	.tag-#{$label}{
		@extend %tag;
		background: $couleur;
		@if $label == 'gris' {
			color: $dark;
		} @else {
			color: #FFF;
		}

	}
}

// Génération des tags à partir de la liste des couleurs @variables.scss
@each $label, $couleur in $couleurs {
	@include generation-tag($label, $couleur);
}